import React, { Component, Fragment } from 'react';
import { Animated } from "react-animated-css";
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AddCursoManualMoodle from '../Calendarios/AddCursoManualMoodle';
import apiCertificaciones from '../../helpers/certificaciones';
import LoaderSecondary from '../Global/LoaderSecondary';
import Loader from '../Global/Loader';

export default class ListSeccionesMoodle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            curso: null,
            date : null,
            overlayShowSeccionManual: false,
            ShowHideModalEditarSeccion: false,
            loading: false,
            modal:{
                open:       false,
                text:       '',
                error:      false,
                loading:    false
            },
        }
        this.overlayShowHideSeccionManual = this.overlayShowHideSeccionManual.bind(this);
        this.modalEditarSeccion = this.modalEditarSeccion.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleEditarSeccion = this.handleEditarSeccion.bind(this);
        this.updateCurso = this.updateCurso.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.borrarCurso = this.borrarCurso.bind(this);
    }

    handleClose() {
        this.props.handleFuncion()
    }
    overlayShowHideSeccionManual() {
        this.setState(prevState => ({
            ...this.state,
            overlayShowSeccionManual: !prevState.overlayShowSeccionManual
        }));
    }

    handleEditarSeccion(fecha,cursoId) {
        if(!!fecha && !!cursoId){
            let mes = fecha.getMonth()+1;
            let formatDate = `${fecha.getFullYear()}-${mes<10 ? '0' : ''}${mes}-${fecha.getDate()}`;
            this.setState(prevState => ({
                ...this.state,
                ShowHideModalEditarSeccion: !prevState.ShowHideModalEditarSeccion,
                date: formatDate,
                curso: cursoId
            }));
        }else{
            this.setState(prevState => ({
                ...this.state,
                ShowHideModalEditarSeccion: !prevState.ShowHideModalEditarSeccion
            }));
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            },
        }))
        this.handleClose()
        this.props.reloadApi()
    }

    modalValidacion() {
        return (
            <div className="custom-modal" style={{zIndex: '100000'}}>
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Calendarios</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.handleModal}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {!this.state.modal.loading ?
                            this.state.modal.text ?
                                <div>
                                    {this.state.modal.error === true ?
                                        <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                        :
                                        <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    }
                                    <p>{this.state.modal.text}</p>
                                </div>
                            : <></> 
                        :
                            <Loader/>}
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                            {this.state.modal.loading ? <LoaderSecondary/> : 'Cerrar'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    async borrarCurso(cursoId){
        this.setState({
            ...this.state,
            loading: true,
            modal:{
                open:       true,
                text:       '',
                error:      false,
                loading:    true
            },
        })
        try{
            await apiCertificaciones.deleteCurso(localStorage.getItem('token'),cursoId)
        }catch(e){
            console.log(e)
            this.setState({
                ...this.state,
                loading: false,
                modal:{
                    open:       true,
                    text:       'Ocurrio un error al borrar el curso',
                    error:      true,
                },
            })
        }
        this.setState({
            ...this.state,
            loading: false,
            modal:{
                open:       true,
                text:       'Curso borrado correctamente',
                error:      false,
            },
        })
    }

    async updateCurso(){
        this.setState({
            ...this.state,
            loading: true
        })
        try{
            let date = new Date(this.state.date+ ' 00:00:00');
            let mes = date.getMonth()+1;
            let formatDate = `${date.getFullYear()}-${mes<10 ? '0' : ''}${mes}-${date.getDate()}`;
            let body ={
                fecha_inicio: formatDate,
                estado: 2
            }
            await apiCertificaciones.patchCurso(localStorage.getItem('token'),body,this.state.curso)
        }catch(e){
            console.log(e)
            this.setState({
                ...this.state,
                loading: false,
                modal:{
                    open:       true,
                    text:       'Ocurrio un error al editar el curso',
                    error:      true,
                },
            })
        }
        this.setState({
            ...this.state,
            loading: false,
            modal:{
                open:       true,
                text:       'Curso editado correctamente',
                error:      false,
            },
        })
    }

    handleInput(e) {
        const { value } = e.target;
        this.setState({
            ...this.state,
            date: value
        })
    }

    modalEditarSeccion() {
        if (this.state.ShowHideModalEditarSeccion === true) {
            return (
                <div className="my-modal zindextop">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <h6>Editar seccion</h6>
                                <button onClick={this.handleEditarSeccion} className="btn btn-close"><i className="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div className="my-modal-body text-left">
                            <div className="form-group">
                                <label htmlFor="seccion_nombre">Nombre</label>
                                <input id="seccion_nombre" type="text" className="form-control" name="seccion_nombre" placeholder={this.props.categoriaNombre.filter(categoria => categoria.id === this.props.categoria)[0].nombre} disabled required></input>
                                <div className="invalid-feedback">Ingresa el nombre del curso</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="seccion_fecha_inicio">Fecha de inicio</label>
                                <input id="seccion_fecha_inicio" type="date" className="form-control" name="seccion_fecha_inicio" defaultValue={this.state.date} onChange={this.handleInput} required></input>
                                <div className="invalid-feedback">Ingresa la fecha del curso</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                        </div>
                        <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                            <button className="btn btn-primary" onClick={this.updateCurso}>{this.state.loading ? <LoaderSecondary/> : 'Guardar'}</button>
                            <button className="btn btn-secondary ml-2" onClick={this.handleEditarSeccion} disabled={this.state.loading ? true : false}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }
    
    render() {
        let cursos = this.props.cursos[this.props.categoria]
        const defaultSortedBy = [{
            dataField: "startdate",
            order: "desc"
        }];

        const columns = [
            {
                dataField: 'id',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
                    return null;
                },
                text: 'Id',
                align: 'center',
                headerClasses: 'border-0',
                uniqueData: "id",
                headerStyle: () => {
                    return { width: "5%" };
                },
                formatter: (cellContent, row) => {
                    return (
                        <span className="badge badge-primary">{row.estado !== 0 ? row.id : 'N/a'}</span>
                    );
                }
            },
            {
                dataField: 'shortname',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Nombre',
                headerClasses: 'border-0',
                headerStyle: () => {
                    return { width: "55%" };
                },
                uniqueData: "shortname",
                // style: {
                //     width: "200px"
                // }
            },
            {
                dataField: 'startdate',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Fecha de Inicio',
                headerClasses: 'border-0',
                uniqueData: "startdate",
                style: {
                    width: "10%",
                    wordBreak: "break-word"
                },
                formatter: (cellContent, row) => {
                    return (
                        <span>{new Date(row.startdate).toLocaleDateString()}</span>
                    );
                }
            },
            {
                dataField: 'estado',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
                    return null;
                },
                text: 'Estado',
                headerClasses: 'border-0',
                uniqueData: "estado",
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cellContent, row) => {
                    let status = row.estado;
                    if (status === 1) {
                        return (
                            <span className="badge badge-success" key={row.id}>Existente</span>
                        );
                    }else if(status === 2){
                        return (
                            <span className="badge badge-warning" key={row.id}>Confirmado</span>
                        );
                    }else{
                        return (
                            <span className="badge badge-danger" key={row.id}>Pendiente</span>
                        );
                    }
                }
            },
            {
                dataField: 'acciones',
                text: 'Acciones',
                headerClasses: 'border-0',
                uniqueData: "acciones",
                headerStyle: () => {
                    return { width: "20%", textAlign: 'center' };
                },
                formatter: (cellContent, row) => {
                    if(row.estado === 2){
                        return (
                            <div style={{textAlign: 'center'}}>
                                <button onClick={() => this.handleEditarSeccion(row.startdate,row.id)} className="btn btn-secondary mr-3">Editar</button>
                                <button onClick={()=> this.borrarCurso(row.id)} className="btn btn-danger">Borrar</button>
                            </div>);
                    }
                }
        
            }
        ];
        const rowClasses = 'custom-row-class';
        
        const MySearch = (props) => {
            let input;
            const handleClick = () => {
                props.onSearch(input.value);
            };
            return (
                <input
                    id="searchCustom"
                    className="form-control input-search custom-big-search"
                    ref={n => input = n}
                    type="text"
                    placeholder="Buscar"
                    onChange={handleClick}
                />
            );
        };

        let categoryName = this.props.categoriaNombre.filter(categoria => categoria.id === this.props.categoria)[0].nombre;

            return (
                <div>
                    {this.state.modal.open ? this.modalValidacion() : <></>}
                    {this.modalEditarSeccion()}
                    <AddCursoManualMoodle prodId={this.props.prodId} reloadApi={this.props.reloadApi} categoriaId={this.props.categoria} categoriaNombre={categoryName} handleFuncion={this.overlayShowHideSeccionManual} show={this.state.overlayShowSeccionManual} closePadre={this.props.handleFuncion}></AddCursoManualMoodle>

                    <div className="my-modal">

                        <div className="my-modal-content max-w-90">
                            <div className="my-modal-header">
                                <div className="row d-flex justify-content-between align-items-center">
                                    <h6>{categoryName}</h6>
                                    <button onClick={() => this.handleClose()} className="btn btn-close"><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="my-modal-body text-left min-h-50 pt-0">
                                <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                    <ToolkitProvider
                                        keyField='id'
                                        data={cursos}
                                        columns={columns}
                                        search={<MySearch />}
                                    >
                                        {
                                            props => (
                                                <Fragment>
                                                    <div className="search-group d-flex align-items-center justify-content-start pt-3 pb-2 sticky-top bg-white" key={4898794654654}>
                                                        <label htmlFor="searchCustom" className="customlabelSearch">Buscar cursos</label>
                                                        <MySearch
                                                            {...props.searchProps}
                                                        />
                                                    </div>

                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        bordered={false}
                                                        headerClasses="header-class"
                                                        pagination={paginationFactory()}
                                                        {...props.baseProps}
                                                        rowClasses={rowClasses}
                                                        paginationSize={50}
                                                        defaultSorted={defaultSortedBy}
                                                    />

                                                </Fragment>
                                            )
                                        }
                                    </ToolkitProvider>
                                </Animated>
                            </div>
                            <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                                <button className="btn btn-primary" onClick={() => this.overlayShowHideSeccionManual()}>Añadir sección manualmente</button>
                            </div>
                        </div>
                    </div>
                </div>
            )

    }
}
