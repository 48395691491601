import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation/Navigation';
import Loader from '../components/Global/Loader';
import LoaderSecondary from '../components/Global/LoaderSecondary';

// "idCliente": 103,
// "idProducto": 531,
// "idPartner": 0,
// "idUsuario": 1,
// "idCurso": 0,
// "idioma": "es",
// "curso": "Nombre del curso o producto",
// "tipo": "aprobacion_verificado",
// "nombreAlumno": "MISAEL SABANS",
// "ano": 2020, // capusla
// "mes": "SEPTIEMBRE", // captulsa
// "nota": 0,
// "asistencia": 0,
// "consulta": 1

class routeListCertificadosAV extends Component {


    constructor(props) {
        super(props)
        this.state = {
            clientesLoading: false,
            clientes: false,
            validation: "needs-validation",
            modalValidacion: false,
            modal: false,
            buttonDisable: false,
            juramentoJurado: null,
            juramentoModal: false,
            validationEntregar: false,
        }

        this.obtenerClientes = this.obtenerClientes.bind(this);
        this.handleSeleccion = this.handleSeleccion.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.buscarUsuario = this.buscarUsuario.bind(this);
        this.handleEntregar = this.handleEntregar.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.handleEntregarFinal = this.handleEntregarFinal.bind(this);
        this.modalErrorOpen = this.modalErrorOpen.bind(this);
        this.modalJuramento = this.modalJuramento.bind(this);
        this.modalJuramentoOpen = this.modalJuramentoOpen.bind(this);
    }

    componentDidMount() {
        this.obtenerClientes();
    }

    obtenerClientes() {
        this.setState({
            clienteLoading: true
        })

        if (localStorage.getItem('clientes') !== null) {
            this.setState({
                clientes: JSON.parse(localStorage.getItem('clientes')),
                clienteLoading: false,
            })
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/clientes", requestOptions)
                .then(response => response.json())
                .then(result => {
                    var clientes = [];

                    for (let i = 0; i < result.length; i++) {
                        clientes.push({
                            'id': result[i].id,
                            'dominio': result[i].dominio
                        })
                    }
                    this.setState({
                        clientes: clientes,
                        clienteLoading: false,
                    })
                })
                .catch(error => console.log('error', error));
        }
    }

    handleSeleccion(e) {
        const { value, name } = e.target;
        this.setState({
            [name]: value
        })
    }

    selecCliente() {
        if (this.state.clientes) {
            if (this.state.clientes.length === null || this.state.clientes.length === undefined) {
                return <LoaderSecondary></LoaderSecondary>
            } else {
                return <Fragment>
                    <select type="text" className="form-control mb-2" name="id_cliente" onChange={this.handleSeleccion} required>
                        <option value="">Selecciona el cliente</option>
                        {this.state.clientes.map(item => {
                            return <option key={`cliente${item.id}`} value={item.id}>{`${item.id} - ${item.dominio}`}</option>
                        })}
                    </select>
                    <input className="form-control ml-2 mr-2 mb-2" name="usuario" type="email" placeholder="Email de usuario" onChange={this.handleSeleccion} required />
                    <button className="btn btn-primary mb-2" onClick={this.buscarUsuario}>
                        Buscar
                </button>
                </Fragment>
            }
        } else{
            return <LoaderSecondary></LoaderSecondary>
        }
    }

    handleValidation() {
        if (!this.state.id_cliente || !this.state.usuario) {
            return false;
        } else {
            return true;
        }
    }

    buscarUsuario(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.handleValidation() === true) {
            this.setState({
                validation: "needs-validation",
                usuarioData: false,
                comprasLoading: true
            })

            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
            myHeaders.append("cliente", this.state.id_cliente);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/usuarios?filter={\"email\":\"" + this.state.usuario + "\"}", requestOptions)
                .then(response => response.json())
                .then(result => {

                    // console.log(result[0])
                    console.log(result)

                    if (result.length === 0) {
                        this.setState({
                            usuarioData: null,
                            comprasLoading: null
                        })
                    } else {
                        this.setState({
                            usuarioData: true,
                            usuarioDataD: result[0],
                            idOctavio: result[0].id,
                            idCanvas: result[0].idCanvas,
                        })
                        this.obtenerJuramento();
                        this.obtenerCompras();
                    }

                })
                .catch(error => {
                    this.setState({
                        usuarioData: null,
                        comprasLoading: null
                    })
                    console.log('error', error)
                });


        } else {
            this.setState({
                validation: "needs-validation was-validated",
            });
        }
    }

    obtenerJuramento() {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/juramentosdehonor/mijuramento/" + this.state.idOctavio, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                // console.log(result.id)
                if (result.id === undefined) {
                    this.setState({
                        juramentoJurado: false,
                        juramento: null
                    })
                } else {
                    this.setState({
                        juramentoJurado: true,
                        juramento: result
                    })
                }
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    obtenerCompras() {

        this.setState({
            comprasLoading: true
        })


        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("cliente", this.state.id_cliente);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let path= ''
        if(this.state.id_cliente == 126 || this.state.id_cliente == 125){
            path = 'miscursosnew';
        }else{
            path ='miscursos';
        }

        fetch(window.$UrlOctavio + "/cursos/" + path + "/" + this.state.idOctavio, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                // return false;

                if (result.length === 0) {
                    this.setState({
                        comprasLoading: null,
                        compras: false
                    })
                }

                if (result.length === 0) {
                    this.setState({
                        comprasLoading: null,
                        compras: false
                    })
                } else {
                    let comprasNew = [];
                    result.forEach(compra => {
                        if(compra.estado != 'inactivo'){
                            comprasNew.push(compra);
                        }
                    });
                    
                    this.setState({
                        comprasLoading: false,
                        compras: comprasNew
                    })
                }
            })
            .catch(error => {
                this.setState({
                    comprasLoading: null,
                    compras: false
                })
                console.log('error', error)
            });
    }

    juramentoBadge() {
        if (this.state.juramentoJurado === true) {
            return (
                <button type="button" className="btn btn-primary mb-4 d-block w-100">
                    Juramento firmado <span className="badge badge-light"> Si </span>
                </button>
            )
        } else if (this.state.juramentoJurado === false) {
            return (
                <button type="button" className="btn btn-primary d-block mb-4 w-100">
                    Juramento firmado <span className="badge badge-light"> No </span>
                </button>
            )
        } else {
            return false
        }

    }

    usuarioData() {
        if (this.state.usuarioData === undefined) {
            return false
        } else {
            if (this.state.usuarioData === true) {
                return (
                    <Fragment>
                        <h4 className="global-title mb-3">Datos de usuario</h4>
                        <div className="box-white">

                            {this.juramentoBadge()}

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Apellido</span> <span className="ml-1"><b>{this.state.usuarioDataD.apellido}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Nombre</span> <span className="ml-1"><b>{this.state.usuarioDataD.nombre}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Email</span> <span className="ml-1"><b>{this.state.usuarioDataD.email}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>País</span> <span className="ml-1"><b>{this.state.usuarioDataD.pais}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Provincia</span> <span className="ml-1"><b>{this.state.usuarioDataD.provincia}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Ciudad</span> <span className="ml-1"><b>{this.state.usuarioDataD.ciudad}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Calle</span> <span className="ml-1"><b>{this.state.usuarioDataD.calle}</b></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    ID Octavio
                                    <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.id}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    ID Canvas
                            <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.idCanvas}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    ID Hubspot
                            <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.idHubspot}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    ID Magento
                                    <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.idMagento}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    ID Moodle
                                    <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.idMoodle}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Idioma</span>
                                    <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.idioma}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Idioma de cursado</span>
                                    <span className="badge badge-secondary badge-pill">{this.state.usuarioDataD.idiomaDeCursado}</span>
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                )
            } else if (this.state.usuarioData === false) {
                return <div className="box-white"><Loader></Loader></div>
            } else {
                return <div className="box-white"><p>No se encontró ningún usuario con la información proporcionada</p></div>
            }
        }

    }

    handleEntregar(compra, producto, cursos) {

        if(this.state.juramentoJurado === false && this.state.id_cliente != 123){ //Al no cursar en lms, los alumnos de masterchef no firman juramento, se toma nombre con el que se registra
            this.setState({
                juramentoModal: true
            })
            return false;    
        }
        var arr = [];
        for (let i = 0; i < cursos.length; i++) {
            arr.push(cursos[i].fechaFin);
            console.log(cursos[i].fechaFin);
        }
        console.log(arr)
        let fechaActual = new Date();
        let arrayFechas = arr.map((fechaActual) => new Date(fechaActual));
         

        console.log(arrayFechas)
        // si la fecha de hoy esta entre las fechas de inicio y fecha de fin poner la fecha actual sino poner la fecha de cierre de la capsula
        // Si la fecha actual es menor a la fecha de inicio no dar certificado
        var fechaFin = new Date(Math.max.apply(null, arrayFechas));
        console.log(fechaFin)
        var fechaFinFinal = "";
        console.log(fechaFinFinal)

        // console.log(fechaActual)
        // console.log(fechaFin)
        if(fechaActual <= fechaFin){
            fechaFinFinal = fechaActual;
        } else{
            fechaFinFinal = fechaFin
        }
        console.log(fechaFinFinal)

        var anio = fechaFinFinal.getFullYear();
        console.log(anio)
        var mes = fechaFinFinal.getMonth();
        var certIdioma = producto.idioma;

        let nombre = '';
        let identificador = '';
        if(this.state.id_cliente == 123){ //Fuerzo una fecha valida para las suscripciones de masterchef
            anio = new Date().getFullYear();
            mes = new Date().getMonth()
            nombre = this.state.usuarioDataD.nombre + " " + this.state.usuarioDataD.apellido;
            identificador = '12345678'; //Numero random, masterchef no lleva dni en el certificado
        }else{
            nombre = this.state.juramento.nombre + " " + this.state.juramento.apellido;
            identificador = this.state.juramento.identificador;
        }

        switch(certIdioma) {
            case 'es':
                var months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
                break;
            case 'pt':
                var months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
                break;
            case 'ca':
                var months = ["Gen", "Feb", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Des"];
                break;
            case 'ar':
                var months = ["كانون الثاني  ", "شباط  ", " آذار ", "نيسان  ", "أيار  ", "حزيران  ", "تموز  ", "آب  ", "أيلول  ", "تشرين الأول  ", "تشرين الثاني  ", "كانون الأول   "];
                break;
            case 'ru':
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                break;
            default: // en
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                break;
        }

        mes = months[mes];

        let duracion = producto.tiene_cap === 1 && producto.categoriasDB.length > 1 ? (producto.categoriasDB.length - 1) : producto.categoriasDB.length;


        
        // cursos.map( item => {
        //     if(!item.nombre.includes("CAP")){
        //         contador++;
        //     }
        // })

        const finalData = {
            "idCliente": parseInt(this.state.id_cliente),
            "idProducto": producto.id,
            "idPartner": compra.partner == null ? 0 : compra.partner,
            "idUsuario": this.state.idOctavio,
            "idCurso": 0,
            "idioma": producto.idioma,
            "curso": producto.nombre,
            "tipo": "aprobacion_verificado",
            "nombreAlumno": nombre,
            "identificador": identificador,
            "ano": parseInt(anio), // capusla
            "mes": mes, // captulsa
            "nota": 0,
            "asistencia": 0,
            "duracion" : duracion
            // "consulta": 1
        }

        // console.log(finalData)
        // return false;

        this.setState({
            finalData: finalData
        })

        if (finalData.mes === undefined) {
            this.setState({
                modalValidacion: false,
                modalError: true,
                modalEntregando: false,
                modal: true
            })
        } else {
            this.setState({
                modalValidacion: true,
                modalEntregando: false,
                modal: true,
                modalError: false
            })
        }




    }

    handleEntregarFinal() {
        this.setState({
            modalEntregando: true,
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(this.state.finalData);

        // console.log(raw);
        // return false;

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // console.log(raw)

        fetch(window.$UrlApiDev + "/certificados", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.error) {
                    this.setState({
                        modaltexto: "Ocurrió un error al intentar generar el certificado",
                        modalEntregando: null,
                        modalError: true
                    })
                } else {
                    this.setState({
                        modaltexto: "Se entregó correctamente el certificado",
                        modalEntregando: 1,
                        modalError: 0
                    })
                }

            })
            .catch(error => {
                console.log('error', error)
                this.setState({
                    modaltexto: "Ocurrió un error al intentar generar el certificado",
                    modalEntregando: null,
                })
            });

    }

    modalOpen() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        if (this.state.modal === true) {
            this.setState({
                modaltexto: false
            })
        }
    }

    modalErrorOpen() {
        this.setState(prevState => ({
            modalError: !prevState.modalError
        }));
    }

    modalJuramentoOpen() {
        this.setState(prevState => ({
            juramentoModal: !prevState.juramentoModal
        }));
    }

    

    buttonsModal() {
        if (this.state.modalEntregando === false) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button className="btn btn-secondary mr-2" onClick={this.modalOpen}>
                        Cancelar
                            </button>
                    <button className="btn btn-primary" onClick={this.handleEntregarFinal}>
                        Aceptar
                    </button>
                </div>
            )
        } else if (this.state.modalEntregando === null) {
            return false
        } else if (this.state.modalEntregando === 1) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button className="btn btn-secondary mr-2" onClick={this.modalOpen}>
                        Cerrar
                </button>
                </div>
            )
        } else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={true} className="btn btn-primary mr-2">
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        Generando...
                    </button>
                </div>
            )
        }
    }

    modalValidacion() {
        if (this.state.modal === true) {
            return (
                <div className="custom-modal">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Entregar certificado</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={this.modalOpen}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white text-center">
                            {this.state.modaltexto
                                ?
                                <div>
                                    {this.state.modalError === true ?
                                        <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                        :
                                        <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    }
                                    <p>{this.state.modaltexto}</p>
                                </div>
                                : <p>¿Estás seguro de entregar este certificado de validación aprobado?</p>
                            }
                        </div>
                        {this.buttonsModal()}
                    </div>
                </div>
            )
        }
    }

    modalError() {
        if (this.state.modalError === true) {
            return (
                <div className="custom-modal">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Ocurrió un error</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={this.modalErrorOpen}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white text-center">
                            {this.state.modalError === true ?
                                <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                :
                                <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                            }
                            <p>Información de fecha no válida para generar el certificado</p>
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.modalError === 0) {
            return (
                <div className="custom-modal">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Certificado entregado con éxito</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={this.modalErrorOpen}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white text-center">
                            {this.state.modalError === true ?
                                <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                :
                                <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                            }
                            <p>Información de fecha no válida para generar el certificado</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    modalJuramento() {
        if (this.state.juramentoModal === true) {
            return (
                <div className="custom-modal">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>El usuario no tiene el juramento firmado</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={this.modalJuramentoOpen}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white text-center">
                                <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                <p>Debe firmar juramento</p>
                        </div>
                    </div>
                </div>
            )
        } else{
            return false
        }
    }

    comprasData() {
        if (this.state.comprasLoading === undefined) {
            return false
        } else {
            if (this.state.comprasLoading === true) {
                return (
                    <div className="box-white">
                        <Loader></Loader>
                    </div>
                )
            } else if (this.state.comprasLoading === false) {
                return (
                    <div>
                        <h4 className="global-title mb-3">Cursos</h4>
                        {
                            this.state.compras.map((compra, index) => {
                                return <div className="box-white p-4 mb-3" key={compra.compraId}>
                                    {compra.productos.map(producto => {
                                        return <div className="card-body" key={producto.id}>
                                            <div className="d-flex align-items-center">
                                                <h4 className="mb-4">{producto.nombre}</h4>
                                                {/* <span className="badge badge-primary ml-3 mb-4">
                                                    Entregado
                                                </span> */}
                                            </div>
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <span>Curso</span>
                                                    <span>Nota</span>
                                                </li>
                                                {producto.cursos.map(curso => {
                                                    return <li className="list-group-item d-flex justify-content-between align-items-center" key={curso.id}>
                                                        {curso.nombre}
                                                        {curso.calificaciones !== undefined &&
                                                            <span className="badge badge-primary badge-pill">
                                                                {console.log(producto)}
                                                                {producto.idLms === 2 
                                                                ?
                                                                curso.grade
                                                                :
                                                                curso.calificaciones.final_score
                                                                }
                                                            </span>
                                                        }
                                                    </li>
                                                })}
                                            </ul>
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-primary mt-3" onClick={() => this.handleEntregar(compra, producto, producto.cursos)}> Entregar certificado </button>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            })}
                    </div>
                )
            } else {
                return <div className="box-white"><p>No se encontraron compras con el ususario seleccionado</p></div>
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Navigation />

                <div className="bg-light pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="navigation-breadcrumb">Inicio / Certificados</label>
                                <h5>Certificado de Aprobación Verificado</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <form action="" onSubmit={this.buscarUsuario} className={this.state.validation}>
                                    <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-start align-items-center">
                                        {this.selecCliente()}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-3">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-2">
                            {this.usuarioData()}
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-2">
                            {this.comprasData()}
                        </div>
                    </div>
                </div>

                {
                    this.state.modalValidacion
                        ?
                        this.modalValidacion()
                        :
                        this.modalError()
                }
                {this.modalJuramento()}
            </React.Fragment>
        )
    }

}

export default routeListCertificadosAV;
