import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import Loader from '../Global/Loader';
import DataTable from 'react-data-table-component';
import store from '../../store';
import { connect } from 'react-redux';
import RightOverlay from '../RightOverlay/RightOverlay'
import CertificadosFormEditA from '../Certificados/CertificadosEditA';

const mySweetTheme = {
  rows: {
    height: '60px'
  },
}



class ListCertificados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificados: [],
      isLoaded: false,
      rerender: [],
      count: 0,
      overlayContent: '', // Corresponde al contenido que lleva adentro del RightOverlay
      overlayShow: false, // Es el estado del RightOverlay
      overlayEditShow: false, // Es el estado del RightOverlay
      IdEdit: null
    }
    store.subscribe(() => {
      this.setState({
        rerender: store.getState().rerender
      });
    })
    this.handlePagination = this.handlePagination.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.overlayShowHide = this.overlayShowHide.bind(this)
  }

  handleEdit(id) {
    console.log()
    this.setState({
      overlayEditShow: true,
      IdEdit: id
    })
  }

  overlayShowHide(){
    this.setState(prevState => ({
      overlayEditShow: !prevState.overlayEditShow,
      IdEdit: null
    }));
  }

  componentWillUpdate(prevProps, prevState) {
    const ant = this.state.rerender;
    if (ant !== prevState.rerender) {
      if (this.state.rerender !== null) {
        this.Fetch();
      }
    }
  }

  componentWillReceiveProps() {
    this.Fetch();
  }

  componentDidMount() {
    this.Fetch();
  }

  Fetch() {
    var myHeaders = new Headers();
    myHeaders.append("Auth-Token", localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(window.$UrlApiDev + "/certificacion/", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)

        this.setState({
          certificados: result,
          isLoaded: true,
          count: result.length
        })
      })
      .catch(error => console.log('error', error));
  }

  handlePagination() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  render() {

    const columns = [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
        width: '80px',
        cell: row => <span className="badge badge-secondary">{row.id}</span>,
        ignoreRowClick: true,
      },
      {
        name: 'Cliente ID',
        selector: 'id_cliente',
        sortable: true,
        cell: row => <span className="badge badge-secondary">{row.id_cliente}</span>,
        width: '80px',
        ignoreRowClick: true,
      },
      {
        name: 'Producto ID',
        selector: 'id_producto',
        width: '80px',
        cell: row => <span className="badge badge-secondary">{row.id_producto}</span>,
        sortable: true,
        ignoreRowClick: true,
      },
      {
        name: 'Template ID',
        selector: 'id_template',
        sortable: true,
        cell: row => <span className="badge badge-secondary">{row.id_template}</span>,
        width: '90px',
        ignoreRowClick: true,
      },
      // {
      //   name: 'Partner ID',
      //   selector: 'id_partner',
      //   sortable: true,
      //   cell: row => <span className="badge badge-secondary">
      //     {row.id_partner === undefined ? "X" : row.id_partner}
      //   </span>,
      //   width: '90px',
      //   ignoreRowClick: true,
      // },
      {
        name: 'Nombre de producto',
        selector: 'nombre_producto',
        ignoreRowClick: true,
        width: '300px',
        sortable: true,
      },
      // {
      //   name: 'Idioma',
      //   selector: 'idioma',
      //   sortable: true,
      //   ignoreRowClick: true,
      //   cell: row => {
      //     console.log()
      //       return <span className="badge badge-primary text-uppercase">
      //         {row.idioma === undefined ? "es" : row.idioma}
      //       </span>;
      //   }
      // },
      {
        name: 'Tipo de Certificado',
        selector: 'tipo',
        ignoreRowClick: true,
        sortable: true,
      },
      {
        name: 'Estado',
        selector: 'estado',
        sortable: true,
        ignoreRowClick: true,
        cell: row => {
          if (row.estado === "1") {
            return <span className="badge badge-success">Activo</span>;
          } else {
            return <span className="badge badge-danger">Inactivo</span>;
          }
        }
      },
      {
        name: 'Acciones',
        wrap: false,
        ignoreRowClick: true,
        cell: row =>
          <button onClick={() => this.handleEdit(row.id)} className="btn btn-secondary mr-3">Editar</button>
      }
    ];

    var { isLoaded, certificados } = this.state;
    if (!isLoaded) {
      return (
        <Loader />
      )
    } else {
      return (
        <div>
          <RightOverlay id={this.state.IdEdit} overlayContent={CertificadosFormEditA} overlayShowHide={this.overlayShowHide} overlayShow={this.state.overlayEditShow}/>
          <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
            <div className="container">

              <div className="row">
                <div className="col-12 text-right">
                  <h6 className="ml-4 mr-2"><span className="badge badge-primary mr-2">{this.state.count}</span> Resultados</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">

                  <DataTable
                    noHeader={true}
                    columns={columns}
                    title={false}
                    data={certificados}
                    customTheme={mySweetTheme}
                    pagination={true}
                    paginationPerPage={20}
                    highlightOnHover={true}
                    striped={true}
                    pointerOnHover={false}
                    overflowY={false}
                    onChangePage={this.handlePagination}
                  />

                </div>
              </div>
            </div>
          </Animated>
        </div>
      )
    }

  }
}

const mapStateToProps = state => ({
  rerenderCertificados: state.rerenderCertificados
})

export default connect(mapStateToProps)(ListCertificados)