import React, { Component } from 'react';
import HeaderForm from '../Global/HeaderForm';
import IconLarge from '../Global/IconLarge';
import apiCertificaciones from '../../helpers/certificaciones';
import LoaderSecondary from '../Global/LoaderSecondary';
// import { Redirect } from 'react-router-dom';

const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"];

export default class AddCursoManualMoodle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonDisable: false,
            validation: "needs-validation",
            data: {},
            nombreCategoria: this.props.categoriaNombre,
            modal:{
                open:       false,
                text:       '',
                error:      false,
            },
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleModal = this.handleModal.bind(this);
    }

    handleClose() {
        this.props.handleFuncion()
    }

    handleInput(e) {
        const { value, name } = e.target;
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            },
        })
    }

    handleValidation() {
        this.setState({
            validation: "needs-validation was-validated",
        });
        if (!this.state.data.fecha_inicio) {
            return false;
        } else {
            return true;
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.handleValidation()) {
            this.setState({
                buttonDisable: true,
            })
            try{
                let date = new Date(this.state.data.fecha_inicio+ ' 00:00:00');
                let mes = date.getMonth()+1;
                let formatDate = `${date.getFullYear()}-${mes<10 ? '0' : ''}${mes}-${date.getDate()}`;
                let body ={
                    id_categoria: this.props.categoriaId,
                    fecha_inicio: formatDate,
                    estado: 2
                }
                await apiCertificaciones.postCursoCalendario(localStorage.getItem('token'),body);
            }catch(e){
                console.log(e);
                this.setState({
                    ...this.state,
                    modal:{
                        open:       true,
                        text:       'Error al guardar el curso',
                        error:      true,
                    },
                })
            }
            this.setState({
                ...this.state,
                modal:{
                    open:       true,
                    text:       'Curso guardado correctamente',
                    error:      false,
                },
                buttonDisable: false
            });
            return false;
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            },
        }))
        this.handleClose()
        this.props.reloadApi()
    }

    modalValidacion() {
        return (
            <div className="custom-modal" style={{zIndex: '100000'}}>
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Calendarios</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.handleModal}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {this.state.modal.text ?
                            <div>
                                {this.state.modal.error === true ?
                                    <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    :
                                    <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                }
                                <p>{this.state.modal.text}</p>
                            </div>
                        : <></>}
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let fecha = this.state.data.fecha_inicio ? new Date(this.state.data.fecha_inicio) : null;
        if (this.props.show) {
            return (<>
                {this.state.modal.open ? this.modalValidacion() : <></>}
                <div className="right-overlay zindextop">
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Añadir nueva sección</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.handleClose()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">

                                    {this.state.estado ? false :

                                        <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>

                                            <HeaderForm title="Información de la sección" content={{}} position="bottom"></HeaderForm>

                                            <div className="form-group">
                                                <label htmlFor="seccion_nombre">Nombre completo</label>
                                                <input disabled id="seccion_nombre" type="text" className="form-control" name="seccion_nombre" placeholder="Nombre de la sección" onChange={this.handleInput} required value={this.state.nombreCategoria}></input>
                                                <div className="invalid-feedback">Ingresa el nombre</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="seccion_nombre_interno">Nombre corto</label>
                                                <input id="seccion_nombre_interno" type="text" className="form-control" name="seccion_nombre_interno" placeholder="Nombre de la sección" disabled="disabled" value={this.state.nombreCategoria + (!!fecha ? (' - '+ (fecha.getDate()+1) + ' '+ months[fecha.getMonth()] + ' '+fecha.getFullYear()) : '')}></input>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="fecha_inicio">Fecha de inicio</label>
                                                <input id="fecha_inicio" type="date" className="form-control" name="fecha_inicio" onChange={this.handleInput} required></input>
                                                <div className="invalid-feedback">Ingresa la fecha de inicio</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>

                                        </form>

                                    }

                                    {this.state.estado === 400 &&
                                        <div>
                                            <IconLarge icon="fa-close" size="4" color="text-danger" animation="shake" />
                                            <p><b>Error</b></p>
                                            <p>Estado: {this.state.estado} <br></br></p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-center">
                            <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={(e) => this.handleSubmit(e)}>
                                {this.state.buttonDisable ? <LoaderSecondary/> : 'Añadir'}
                            </button>
                            <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </>);
        } else {
            return false;
        }
    }
}
