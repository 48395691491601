import React, { Component, Fragment } from 'react';
import LoaderSecondary from '../components/Global/LoaderSecondary';
import Loader from '../components/Global/Loader';
import Navigation from '../components/Navigation/Navigation';
import { Animated } from "react-animated-css";
import { Link, Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import HeaderForm from '../components/Global/HeaderForm';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


const columns = [
    {
        dataField: 'id',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
            return null;
        },
        text: 'Id',
        align: 'center',
        headerClasses: 'border-0',
        uniqueData: "id",
        headerStyle: () => {
            return { width: "5%" };
        },
        formatter: (cellContent, row) => {
            return (
                <span className="badge badge-info">{row.id}</span>
            );
        }
    },
    {
        dataField: 'idMagento',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Id Magento',
        align: 'center',
        headerClasses: 'border-0',
        uniqueData: "idMagento",
        headerStyle: () => {
            return { width: "8%" };
        },
        formatter: (cellContent, row) => {
            return (
                <span className="badge badge-secondary">{row.idMagento}</span>
            );
        }
    },
    {
        dataField: 'idHubspot',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Id Hubspot',
        align: 'center',
        headerClasses: 'border-0',
        uniqueData: "idHubspot",
        headerStyle: () => {
            return { width: "8%" };
        },
        formatter: (cellContent, row) => {
            return (
                <span className="badge badge-secondary">{row.idHubspot}</span>
            );
        }
    },
    {
        dataField: 'nombre',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Nombre',
        headerClasses: 'border-0',
        headerStyle: () => {
            return { width: "40%" };
        },
        uniqueData: "nombre",
        style: {
            width: "200px"
        }
    },
    {
        dataField: 'sku',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Sku',
        headerClasses: 'border-0',
        uniqueData: "sku"
    },
    {
        dataField: 'precio',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Precio',
        headerClasses: 'border-0',
        uniqueData: "precio"
    },
    {
        dataField: 'tipo',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort"></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
            return null;
        },
        text: 'Tipo',
        headerClasses: 'border-0',
        uniqueData: "tipo"
    },
    {
        dataField: 'estado',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
            else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
            else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
            return null;
        },
        text: 'Estado',
        headerClasses: 'border-0',
        uniqueData: "estado",
        formatter: (cellContent, row) => {
            if (row.estado === 1) {
                return (
                    <span className="badge badge-success" key={row.id}>Activo</span>
                );
            }
            return (
                <span className="badge badge-danger" key={row.id}>Inactivo</span>
            );
        }
    },
    {
        dataField: 'acciones',
        text: 'Acciones',
        headerClasses: 'border-0',
        uniqueData: "acciones",
        formatter: (row) => {
            return (
                <Link to={`/producto/${row}`} className="btn btn-secondary mr-3">Editar</Link>
            );
        }

    }
];
const rowClasses = 'custom-row-class';
// const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const MySearch = (props) => {
    let input;
    const handleClick = () => {
        props.onSearch(input.value);
    };
    return (
        <input
            id="searchCustom"
            className="form-control input-search custom-big-search"
            ref={n => input = n}
            type="text"
            placeholder="Buscar"
            onChange={handleClick}
        />
    );
};

var idiomas = [];
var categorias = [];
var producto_carousel = false;

class routeProductos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientes: null,
            productos: null,
            overlayShow: false,
            validation: "needs-validation",
            data: {
                producto_nombre: false,
                producto_sku: false,
                producto_precio: false,
                producto_cuotas: false,
                producto_tipo: false,
                producto_archivo: false,
                producto_categorias: false,
                producto_idiomas: false,
                producto_partner: false,
                producto_lms: false,
                producto_carousel: false,
                producto_estado: false,
            },
            sku: false,
            skuTipo: "",
            skuPart: "",
            skuPrecio: "",
            skuCuotas: "",
            categoriasLoading: true,
            categorias: [],
            clienteSeleccionado: null,
            categoriasSingle: [],
            idiomas: [],
            producto_carousel: false,
            producto_imagen: false,
            buttonDisable: false,
            redirect: false,
            productIdCreated: false
        }
        this.handleObtenerProductos = this.handleObtenerProductos.bind(this);
        this.selectClient = this.selectClient.bind(this);
        this.handleClienteSeleccionado = this.handleClienteSeleccionado.bind(this);
        this.overlayShowHide = this.overlayShowHide.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleObtenerCategorias = this.handleObtenerCategorias.bind(this);
        this.handlePost = this.handlePost.bind(this);
    }

    componentDidMount() {
        var clientes = JSON.parse(localStorage.getItem('clientes'));
        this.setState({
            clientes: clientes,
        })
    }

    handleClienteSeleccionado(e) {
        const { value } = e.target;
        this.setState({
            clienteSeleccionado: value
        })
        this.handleObtenerProductos(value)
        this.handleObtenerCategorias();
    }

    handleObtenerProductos(value) {
        this.setState({
            productoLoading: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("cliente", value);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/productos", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                var productos = [];
                for (let i = 0; i < result.length; i++) {
                    productos.push({
                        'id': result[i].id,
                        'idMagento': result[i].idMagento,
                        'idHubspot': result[i].idHubspot,
                        'nombre': result[i].nombre,
                        'sku': result[i].sku,
                        'precio': result[i].precio,
                        'tipo': result[i].tipo,
                        'estado': result[i].estado,
                        'acciones': result[i].id
                    })
                }
                if (result.error) {
                    this.setState({
                        productoLoading: false,
                        productos: false
                    })
                } else {
                    this.setState({
                        productoLoading: false,
                        productos: productos
                    })
                }

            })
            .catch(error => {
                console.log('error', error)
            })
    }

    handleCheck(e) {
        if (e.target.name === "producto_idiomas") {
            if (e.target.checked === true) {
                idiomas.push(e.target.id)
            } else {
                for (var i = 0; i < idiomas.length; i++) {

                    if (idiomas[i] === e.target.id) {
                        idiomas.splice(i, 1);
                    }

                }
            }
        }
        if (e.target.name === "producto_categorias") {
            if (e.target.checked === true) {
                categorias.push(parseInt(e.target.id))
            } else {
                for (var i = 0; i < categorias.length; i++) {
                    if (categorias[i] === parseInt(e.target.id)) {
                        categorias.splice(i, 1);
                    }
                }
            }
        }

        if (e.target.name === "producto_carousel") {
            if (e.target.checked === true) {
                producto_carousel = 1;
            } else {
                producto_carousel = 0;
            }
        }


    }

    async handleInput(e) {
        var clienteSku;

        this.state.clientes.map(cliente => {
            if (cliente.id === parseInt(this.state.clienteSeleccionado)) {
                clienteSku = cliente.path.toUpperCase();
            }
        })
        const { value, name } = e.target;

        // if(name === "producto_nombre"){
        //     this.setState({
        //         skuFinal: "ASDasd45645" + value
        //     })
        // }
        var tipo;
        var skuPart;

        if (name === "producto_tipo") {
            if (value === "certificado") {
                tipo = "CERT"
            } else if (value === "evento") {
                tipo = "EVEN"
            } else if (value === "master") {
                tipo = "MAST"
            } else if (value === "producto") {
                tipo = "PROD"
            } else {
                tipo = "";
            }
            var al = getRandomInt(0, 99999999999);
            this.setState({
                skuTipo: tipo + al + clienteSku
            })
        }

        if (name === "producto_partner") {
            if (value === "0") {
                skuPart = "PARSIN"
            } else if (value === "9") {
                skuPart = "PARCON"
            } else if (value === "10") {
                skuPart = "PARAME"
            } else if (value === "11") {
                skuPart = "PARARE"
            } else if (value === "12") {
                skuPart = "PARUAF"
            } else {
                skuPart = "";
            }
            this.setState({
                skuPart: skuPart
            })
        }

        if (name === "producto_precio") {
            this.setState({
                skuPrecio: "PRE" + value
            })
        }

        if (name === "producto_cuotas") {
            this.setState({
                skuCuotas: "CUOT" + value
            })
        }

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        }


        if (name === "producto_archivo") {
            const fileToBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            const imagen = await fileToBase64(e.target.files[0]);
            this.setState({
                producto_imagen: imagen.split(",")[1]
            })
        }

        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            },
            sku: this.state.skuTipo,
        })
        // console.log([name])
        // console.log([value])
    }

    handleObtenerCategoriaId() {
        var arrayTemp = []
        if (this.state.categorias.length !== 0) {
            for (let i = 0; i < this.state.categorias.length; i++) {
                var myHeaders = new Headers();
                myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(window.$UrlOctavio + "/categorias/" + this.state.categorias[i].id, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        // console.log(result)
                        if (result.error) {
                            this.setState({
                                categoriasSingle: false
                            })
                        } else {
                            arrayTemp.push(result)
                        }

                        if ([i][0] === this.state.categorias.length - 1) {
                            this.setState({
                                categoriasLoading: false,
                                categoriasSingle: arrayTemp
                            })
                        }



                    })
                    .catch(error => {
                        console.log('error', error)
                    })


            }

        }



    }

    handleObtenerCategorias(value) {
        this.setState({
            categoriasLoading: true,
            categorias: [],
            categoriasSingle: []
        })
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("cliente", value);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/categorias", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    this.setState({
                        categorias: []
                    })
                } else {
                    this.setState({
                        categorias: result
                    })
                    this.handleObtenerCategoriaId()
                }

            })
            .catch(error => {
                console.log('error', error)
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            validation: "needs-validation was-validated",
        });
        if (this.handleValidation() === true) {
            // console.log("No pasó")
            return false;
        } else {
            // console.log("Pasooo")
            this.handlePost();
        };
    }


    handleValidation() {

        var validation = [];
        this.state.sku !== false ? validation.push(true) : validation.push(false)
        this.state.data.producto_nombre !== false ? validation.push(true) : validation.push(false)
        this.state.data.producto_precio !== false ? validation.push(true) : validation.push(false)
        this.state.data.producto_cuotas !== false ? validation.push(true) : validation.push(false)
        this.state.data.producto_tipo !== false ? validation.push(true) : validation.push(false)
        this.state.producto_imagen !== false ? validation.push(true) : validation.push(false)
        categorias.length !== 0 ? validation.push(true) : validation.push(false)
        idiomas.length !== 0 ? validation.push(true) : validation.push(false)
        this.state.data.producto_partner !== false ? validation.push(true) : validation.push(false)
        this.state.data.producto_lms !== false ? validation.push(true) : validation.push(false)
        this.state.data.producto_estado !== false ? validation.push(true) : validation.push(false)
        return validation.includes(false);
    }

    handlePost() {
        this.setState({
            buttonDisable: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("cliente", this.state.clienteSeleccionado);

        var raw = JSON.stringify({
            "nombre": this.state.data.producto_nombre,
            "sku": this.state.sku,
            "precio": parseInt(this.state.data.producto_precio),
            // "imagen": this.state.producto_imagen,
            "categorias": categorias,
            "cantidadDeCuotas": parseInt(this.state.data.producto_cuotas),
            "estado": parseInt(this.state.data.producto_estado),
            "idiomas": idiomas,
            "noCarrousel": this.state.data.producto_carousel,
            "tipo": this.state.data.producto_tipo,
            "idLms": parseInt(this.state.data.producto_lms),
            "cursos": {}
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log(raw)

        fetch(window.$UrlOctavio + "/productos", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    buttonDisable: false
                })
                if(result.id !== undefined){
                    this.setState({
                        redirect: true,
                        productIdCreated: result.id
                    })
                } else{
                    this.setState({
                        redirect: false,
                        productIdCreated: false
                    })
                    alert("ocurrió un error al crear el producto")
                }
                
            })
            .catch(error => {
                console.log('error', error)
                this.setState({
                    buttonDisable: false
                })
            });
    }


    overlayShowHide() {
        this.setState(prevState => ({
            overlayShow: !prevState.overlayShow
        }));
        this.handleObtenerCategorias(this.state.clienteSeleccionado)
    }

    overlayNuevoProducto() {
        if (this.state.overlayShow) {
            return <div className="right-overlay">
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Añadir nuevo Producto</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12">

                                <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>

                                    <HeaderForm title="Información del producto" position="bottom"></HeaderForm>

                                    <div className="form-group">
                                        <label htmlFor="producto_nombre">Nombre del producto</label>
                                        <input id="producto_nombre" type="text" className="form-control" name="producto_nombre" placeholder="Nombre del producto" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el nombre del producto</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="producto_sku">SKU</label>
                                        <input id="producto_sku" type="text" className="form-control" name="producto_sku" placeholder="SKU" onChange={this.handleInput} value={this.state.sku} disabled required></input>
                                        <div className="invalid-feedback">Ingresa el SKU</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="producto_precio">Precio</label>
                                        <input id="producto_precio" type="number" className="form-control" name="producto_precio" placeholder="Precio" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el precio</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="producto_cuotas">Cantidad de cuotas</label>
                                        <input id="producto_cuotas" type="number" className="form-control" name="producto_cuotas" placeholder="Cantidad de cuotas" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa la cantidad de cuotas</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="producto_tipo">Tipo</label>
                                        <select id="producto_tipo" type="text" className="form-control" name="producto_tipo" onChange={this.handleInput} required>
                                            <option value="">Selecciona un tipo de producto</option>
                                            <option value="producto">Certificado</option>
                                            <option value="evento">Evento</option>
                                            <option value="master">Master</option>
                                            <option value="producto">Producto</option>
                                        </select>
                                        <div className="invalid-feedback">Selecciona un tipo de producto</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <HeaderForm title="Imagen Miniatura" help="La imagen miniatura es la imagen que será mostrada tanto en el catálogo de Wordpress como en el catálogo de Magento." position="bottom"></HeaderForm>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Subir</span>
                                        </div>
                                        <div className="custom-file">
                                            <input type="file" name="producto_archivo" className="custom-file-input" onChange={this.handleInput} required />
                                            <label className="custom-file-label">Selecciona el archivo</label>
                                        </div>
                                    </div>

                                    {this.state.categoriasLoading === true ? <Loader /> :
                                        <Fragment>
                                            <div className="form-group">
                                                <label htmlFor="producto_categorias">Categorías</label>
                                                {
                                                    this.state.categoriasSingle.length !== 0 &&
                                                    this.state.categoriasSingle.map(item => {
                                                        return <div className="custom-control custom-checkbox" key={item.id}>
                                                            <input type="checkbox" className="custom-control-input" name="producto_categorias" id={`${item.id}`} onChange={this.handleCheck} />
                                                            <label className="custom-control-label" htmlFor={`${item.id}`}>{item.nombre}</label>
                                                        </div>
                                                    })}
                                                <div className="invalid-feedback">Selecciona una o varias categorías</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                    }

                                    <div className="form-group" id="idiomas">
                                        <label htmlFor="producto_idiomas">Idiomas</label>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="producto_idiomas" id="es" onChange={this.handleCheck} />
                                            <label className="custom-control-label" htmlFor="es">Español</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="producto_idiomas" id="en" onChange={this.handleCheck} />
                                            <label className="custom-control-label" htmlFor="en">Inglés</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="producto_idiomas" id="ca" onChange={this.handleCheck} />
                                            <label className="custom-control-label" htmlFor="ca">Catalán</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="producto_idiomas" id="pt" onChange={this.handleCheck} />
                                            <label className="custom-control-label" htmlFor="pt">Portugués</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="producto_idiomas" id="ar" onChange={this.handleCheck} />
                                            <label className="custom-control-label" htmlFor="ar">Árabe</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="producto_idiomas" id="ru" onChange={this.handleCheck} />
                                            <label className="custom-control-label" htmlFor="ru">Ruso</label>
                                        </div>
                                        <div className="invalid-feedback">Selecciona uno idioma o varios idiomas</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <hr />

                                    <div className="form-group">
                                        <label htmlFor="producto_partner">Partner (Oprional)</label>
                                        <select type="text" className="form-control" name="producto_partner" onChange={this.handleInput} required>
                                            <option value="">Selecciona partner</option>
                                            <option value="0">Sin Partner</option>
                                            <option value="9">Conmebol</option>
                                            <option value="10">Americana</option>
                                            <option value="11">Area Andina</option>
                                            <option value="12">UAF</option>
                                        </select>
                                        <div className="invalid-feedback">Selecciona un partner</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <HeaderForm title="LMS" help="Los cursos del producto serán publicados en el LMS seleccionado" position="bottom"></HeaderForm>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input id="canvas" type="radio" name="producto_lms" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                        <label className="custom-control-label" htmlFor="canvas">Canvas</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input id="moodle" type="radio" name="producto_lms" onChange={this.handleInput} value="0" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="moodle">Moodle</label>
                                    </div>
                                    <div className="invalid-feedback">El LMS es requerido</div>
                                    <div className="valid-feedback">Excelente!</div><br></br>
                                    <hr />

                                    <HeaderForm title="No carrousel" help="Corresponde a un producto del tipo No Carrousel" position="bottom"></HeaderForm>

                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" name="producto_carousel" id="producto_carousel" onChange={this.handleCheck} />
                                        <label className="custom-control-label" htmlFor="producto_carousel">No carrusel</label>
                                    </div>
                                    <hr />

                                    <HeaderForm title="Estado del producto" help="Corresponde al estado del producto si será visible o no" position="bottom"></HeaderForm>
                                    <div className="custom-control custom-radio custom-control-inline">

                                        <input id="activo" type="radio" name="producto_estado" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                        <label className="custom-control-label" htmlFor="activo">Activo</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input id="inactivo" type="radio" name="producto_estado" onChange={this.handleInput} value="0" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="inactivo">Inactivo</label>

                                    </div>
                                    <div className="invalid-feedback">El estado es requerido</div>
                                    <div className="valid-feedback">Excelente!</div><br></br>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-center">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={(e) => this.handleSubmit(e)}>
                            {this.state.buttonDisable === false ? "Añadir" : <LoaderSecondary />}
                        </button>
                        {this.state.buttonDisable ? false : <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.overlayShowHide()}>
                            Cancelar
                        </button>}
                    </div>
                </div>
            </div>
        }
    }

    selectClient() {
        if (this.state.clientes === null) {
            return (
                <LoaderSecondary texto="Cargando listado de clientes"></LoaderSecondary>
            )
        } else {
            return (
                <Fragment>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="form-group d-flex align-items-center justify-content-start mb-0">
                            <label className="m-0 mr-3" htmlFor="id_cliente">Cliente</label>
                            <select value={this.state.selectedCliente} id="id_cliente" type="text" className="form-control" name="id_cliente" onChange={this.handleClienteSeleccionado} required>
                                <option value="">Selecciona el cliente</option>
                                {this.state.clientes.map(item => {
                                    return <option key={`cliente${item.id}`} value={item.id}>{`${item.id} - ${item.dominio}`}</option>
                                })}
                            </select>
                            <div className="invalid-feedback">Selecciona un tipo de certificación</div>
                            <div className="valid-feedback">Excelente!</div>
                        </div>
                        {this.state.productoLoading === false ? <button className="btn btn-primary" onClick={() => this.overlayShowHide()}>Añadir producto</button> : false}
                    </div>
                </Fragment>
            )
        }
    }

    renderProductos() {
        if (this.state.productoLoading === true) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Loader></Loader>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (this.state.productos === null) {
                return (
                    false
                )
            } else {
                return (
                    <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField='id'
                                        data={this.state.productos}
                                        columns={columns}
                                        search={<MySearch />}
                                        exportCSV
                                    >
                                        {
                                            props => (
                                                <Fragment>
                                                    <div className="search-group d-flex align-items-center justify-content-start mb-5" key={4898794654654}>
                                                        <label htmlFor="searchCustom" className="customlabelSearch">Buscar productos</label>
                                                        <MySearch
                                                            {...props.searchProps}
                                                        />
                                                        <ExportCSVButton {...props.csvProps} className="ml-3 btn btn-primary customlabelSearch">Exportar CSV</ExportCSVButton>
                                                    </div>

                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        bordered={false}
                                                        headerClasses="header-class"
                                                        pagination={paginationFactory()}
                                                        {...props.baseProps}
                                                        rowClasses={rowClasses}
                                                    />

                                                </Fragment>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </Animated>
                )
            }
        }
    }

    render() {
        if(this.state.redirect){
            return (<Redirect to={`/producto/${this.state.productIdCreated}/?overlay=true`} />)
        }
        return (
            <div>
                {this.overlayNuevoProducto()}
                <Navigation />
                <Fragment>
                    <div className="bg-light pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="navigation-breadcrumb">Inicio &gt Enrollment &gt Productos</label>
                                    <h5>Productos</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                        <div className="container">
                            <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                                <div className="stickyMenu__cliente w-100 mb-2 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0">
                                    {this.selectClient()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                {this.renderProductos()}
            </div>
        )
    }
}

export default routeProductos;