import { createStore } from 'redux';

const reducer = (state, action) => {

    if(action.type === 'SELECT_CLIENT'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            cliente: state.cliente.concat(action.cliente)
        }
    }
    if(action.type === 'LIST_CLIENT'){
        // console.log(state.listClientes.concat(action.listClientes))
        return {
            ...state,
            listClientes: state.listClientes.concat(action.listClientes)
        }
    }
    if(action.type === 'RE_RENDER'){
        return {
            ...state,
            rerender: state.rerender.concat(action.rerender)
        }
    }
    if(action.type === 'TOKEN'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            token: state.token.concat(action.token)
        }
    }
    if(action.type === 'OPEN_TEMPLATE'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            openTextEditor: state.openTextEditor.concat(action.openTextEditor)
        }
    }
    if(action.type === 'TEMPLATE_SELECTED'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            template: state.template.concat(action.template)
        }
    }

    if(action.type === 'RERENDER_CERTIFICADOS'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            rerenderCertificados: state.rerenderCertificados.concat(action.rerenderCertificados)
        }
    }

    if(action.type === 'showSc'){
        return {
            ...state,
            showSc: action.showSc
        }
    }
    
    return state;
    
};

export default createStore(
    reducer, { cliente: [], listClientes : [], rerender: [false], token: [], showSc: false, openTextEditor: [false], template : [], rerenderCertificados : []},
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);