import axios from "axios";

const baseUrl = 'https://certificaciones.capabiliaserver.com/api/v1';

const apiCertificaciones = {

    postCursoCalendario (token,body) {
        return new Promise((resolve, reject) => {
            axios.post(baseUrl+'/calendarios',body,{headers:{"Auth-Token": token}})
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    patchCurso(token,body,id){
        return new Promise((resolve, reject) => {
            axios.patch(`${baseUrl}/calendarios/${id}`,body,{headers:{"Auth-Token": token}})
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getCursosByCategory(token,category,cliente){
        return new Promise((resolve, reject) => {            
            axios.get(`${baseUrl}/calendarios/categoria/${category}/cliente/${cliente}`,{headers: {'Auth-Token': token}})
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    deleteCurso(token,curso){
        return new Promise((resolve, reject) => {
            axios.delete(`${baseUrl}/calendarios/${curso}`,{headers:{"Auth-Token": token}})
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    convertTimeZone(token,fecha, clienteId){
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/calendarios/time`,{date: fecha, cliente:clienteId},{headers:{"Auth-Token": token}})
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

}

export default apiCertificaciones;