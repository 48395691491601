import axios from 'axios';

const moodle = {

    getCoursesByCategory (idCategory,clienteDB) {

        let cliente = this.getURL(clienteDB);
        let url = "?wstoken=" + cliente.token + "&moodlewsrestformat=json&wsfunction=core_course_get_courses_by_field&field=category&value=" + idCategory
        
        return new Promise((resolve, reject) => {
            axios.get(cliente.baseURL+url)
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    createCourse(curso,clienteDB){

        let cliente = this.getURL(clienteDB);
        let url = `?wstoken=${cliente.token}&moodlewsrestformat=json&wsfunction=core_course_create_courses&courses%5B0%5D%5Bfullname%5D=${encodeURI(curso.fullname)}&courses%5B0%5D%5Bcategoryid%5D=${curso.catId}&courses%5B0%5D%5Bshortname%5D=${encodeURI(curso.shortname)}&courses%5B0%5D%5Bstartdate%5D=${encodeURI(curso.stardate)}&courses%5B0%5D%5Benddate%5D=${encodeURI(curso.enddate)}&courses%5B0%5D%5Bidnumber%5D=${encodeURI(curso.sku)}`
        
        return new Promise((resolve, reject) => {
            axios.post(cliente.baseURL+url)
                .then(response => {
                    resolve(response["data"])
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    getURL (clienteDB) {
        let cliente = {
            baseURL: "https://" + clienteDB.dominio + "/" + clienteDB.path_moodle + "/webservice/rest/server.php",
            token: clienteDB.moodleToken
        }
        return cliente;
    }
}

export default moodle;