import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import './assets/css/index.css';
import certTemplates from './routes/templates';
import routeListCertificados from './routes/routeListCertificados';
import listUsuarios from './routes/listUsuarios';
import Login from './components/Login/LoginComponent';
import PrivateRoute from './routes/PrivateRoute';
import store from './store';
import {Provider} from 'react-redux';
import EditTemplate from './routes/editTemplates';
// import routeCertificadosEdit from './routes/routeCertificadosEdit';
// import descargarDemo from './routes/descargarDemo';
import routeListCertificadosAV from './routes/routeListCertificadosAV';
import routeAsistencias from './routes/routeAsistencias';
import routeReportes from './routes/routeReportes';
import openai from './routes/openai';
import routeProductoId from './routes/routeProductoId';
import routeProductos from './routes/routeProductos';
import Escritorio from './components/Escritorio/Escritorio';
import routeListReprogramacion from './routes/routeListReprogramacion';
import routeCalendarios from './routes/routeCalendarios';
import routeCalendarioId from './routes/routeCalendarioId';

window.$UrlApiDev = 'https://certificaciones.capabiliaserver.com/api/v1';
window.$UrlOctavio = 'https://octavio.capabiliaserver.com/api/v1';

const Root = (
    <Provider store={store}>
    <HashRouter>
        <Switch>
            <Route exact path="/login" component={Login}/>
            <PrivateRoute exact path="/escritorio/" component={Escritorio}/>
            <PrivateRoute exact path="/certificados/" component={routeListCertificados}/>
            <PrivateRoute exact path="/certificados-aprobacion-verificado/" component={routeListCertificadosAV}/>
            <PrivateRoute exact path ="/templates/" component={certTemplates}/>
            <PrivateRoute exact path ="/templates/:id" component={EditTemplate}/>
            <PrivateRoute exact path="/usuarios/" component={listUsuarios}/>
            <PrivateRoute exact path="/asistencias/" component={routeAsistencias}/>
            <PrivateRoute exact path="/reportes/" component={routeReportes}/>
            <PrivateRoute exact path="/openai/" component={openai}/>
            <PrivateRoute exact path="/productos/" component={routeProductos}/>
            <PrivateRoute exact path="/producto/:id" component={routeProductoId}/>
            <PrivateRoute exact path="/reprogramacion/" component={routeListReprogramacion}/>
            <PrivateRoute exact path="/calendarios" component={routeCalendarios}/>
            <PrivateRoute exact path="/calendario/:id" component={routeCalendarioId}/>
            {/* <PrivateRoute exact path="/descargardemo" component={descargarDemo}/> */}
            <Redirect from="/" to="/login"/>
        </Switch>
    </HashRouter>
    </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));