import React, { Component, Fragment } from 'react';
import Loader from '../components/Global/Loader';
import { Animated } from "react-animated-css";
import Navigation from '../components/Navigation/Navigation';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoaderSecondary from '../components/Global/LoaderSecondary';
import $ from 'jquery';
import moodle from '../helpers/moodle';
import ListSeccionesMoodle from '../components/Calendarios/ListSeccionesMoodle';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import apiCertificaciones from '../helpers/certificaciones';

const today = new Date();
today.setDate(1);
const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"];
export default class routeCalendarioId extends Component {

    constructor(props) {
        super(props)
        this.state = {
            producto: null,
            cliente: JSON.parse(sessionStorage.getItem('clienteSelected') ? sessionStorage.getItem('clienteSelected') : null),
            loading:{
                producto: true,
                productoUpdate: false,
                cursos: true,
                calendarios: false,
            },
            modal:{
                open:       false,
                text:       null,
                error:      false,
            },
            reload:{
                cursosMoodle: true,
                apiCalendarios: true,
            },
            calendario: false,
            cursos: null,
            idiomaSelected: null,
            dateSelected: [],
            monthsPreview: 6,
            ShowHideModalCalendario : false,
            ShowHideModalSecciones :false,
            ShowHideModalProducto: false,
            ShowHideModalConfirmacion: false
        }

        this.obtenerProducto = this.obtenerProducto.bind(this);
        this.obtenerCursos = this.obtenerCursos.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleModalCalendario = this.handleModalCalendario.bind(this);
        this.modalCalendario = this.modalCalendario.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectDuracion = this.handleSelectDuracion.bind(this);
        this.handleModalSecciones = this.handleModalSecciones.bind(this);
        this.handleModalProducto = this.handleModalProducto.bind(this);
        this.handleModalConfirmacion= this.handleModalConfirmacion.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.guardarProducto = this.guardarProducto.bind(this);
        this.guardarCalendario = this.guardarCalendario.bind(this);
        this.reloadApiCourses = this.reloadApiCourses.bind(this);
        this.crearEnMoodle = this.crearEnMoodle.bind(this);
        this.modalConfirmacion = this.modalConfirmacion.bind(this);
    }

    componentDidMount() {
        this.obtenerProducto();
    }

    obtenerProducto() {
        try{
            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/productos/" + this.props.match.params.id, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.fechas_inicio == null){
                        result.fechas_inicio = [new Date(2023,0,10)];
                    }else{
                        let fechas = [];
                        result.fechas_inicio.forEach(fecha=>{
                            fechas.push(new Date(2023,0,fecha));
                        })
                        result.fechas_inicio = fechas;
                    }
                    this.setState({
                        ...this.state,
                        loading:{
                            ...this.state.loading,
                            producto: false
                        },
                        producto: result,
                        idiomaSelected: Object.keys(result.cursos)[0],
                    })
                    this.obtenerCursos();
                })
                .catch(error => {
                    this.setState({
                        ...this.state,
                        loading:{
                            ...this.state.loading,
                            producto: false
                        },
                        modal:{
                            ...this.state.modal,
                            open: true,
                            error: true,
                            text: 'Ocurrio un error al obtener el producto'
                        }
                    })
                    console.log('error', error)
                })
        }catch(error){
            console.log(error);
        }
    }

    async obtenerCursos(){
        try {
            for(let idioma of Object.keys(this.state.producto.idsMoodle)){

                let cursosByCategory = {};
                let cursosByCategoryApi = {};

                for(let categoria of this.state.producto.idsMoodle[idioma]){

                    cursosByCategory[categoria] = [];
                    if(!!this.state.reload.cursosMoodle){
                        let response = await moodle.getCoursesByCategory(categoria,this.state.cliente);
                        if(!!response){
                            cursosByCategory[categoria] = response.courses;
                            await cursosByCategory[categoria].forEach(curso => {
                                curso.estado = 1;
                                curso.mostrar = true;
                                curso.fechaOriginal = curso.startdate;
                                curso.startdate = new Date(curso.startdate*1000)
                            })
                        }else {
                            this.setState({
                                ...this.state,
                                modal:{
                                    ...this.state.modal,
                                    open: true,
                                    error: true,
                                    text: 'Ocurrio un error al obtener los cursos del producto'
                                }
                            })
                        }
                    }else{
                        cursosByCategory[categoria] = await this.state.cursos[idioma][categoria].filter(curso=>curso.estado === 1);
                    }

                    cursosByCategoryApi[categoria] = [];
                    if(!!this.state.reload.apiCalendarios){
                        try{
                            let response2 = await apiCertificaciones.getCursosByCategory(localStorage.getItem('token'),categoria,this.state.cliente.id);
                            if(!!response2){
                                response2.forEach(curso=>{
                                    let inicio = new Date(curso.fecha_inicio + ' 00:00:00');
                                    let apiCourse = {
                                        id: curso.id_calendario,
                                        catId: curso.id_categoria*1,
                                        startdate: inicio,
                                        estado: 2,
                                        sku: `${this.state.producto.sku}${curso.id_calendario}`,
                                        fullname: !!cursosByCategory[categoria][0] ? cursosByCategory[categoria][0].categoryname : '',
                                        shortname: !!cursosByCategory[categoria][0] ? `${cursosByCategory[categoria][0].categoryname} - ${inicio.getDate()} ${months[inicio.getMonth()]} ${inicio.getFullYear()}` : ''
                                    }
                                    cursosByCategoryApi[categoria].push(apiCourse)
                                })
                            }else {
                                this.setState({
                                    ...this.state,
                                    modal:{
                                        ...this.state.modal,
                                        open: true,
                                        error: true,
                                        text: 'Ocurrio un error al obtener los cursos de la api'
                                    }
                                })
                            }
                        }catch(e){
                            // console.log(e);
                        }
                    }else{
                        cursosByCategoryApi[categoria] = await this.state.cursos[idioma][categoria].filter(curso=>curso.estado === 2);
                    }

                    let balance = this.state.producto.noCarrousel === 1 ? this.state.producto.idsMoodle[idioma].indexOf(categoria) : this.state.producto.idsMoodle[idioma].length > 1 && this.state.producto.tiene_cap == 1 ? this.state.producto.idsMoodle[idioma].length-1 : this.state.producto.idsMoodle[idioma].length;
                    cursosByCategoryApi[categoria].forEach(curso =>{
                        cursosByCategory[categoria].push(curso)
                    })

                    let finDelCalendario = new Date(today)
                    finDelCalendario.setDate(28)
                    finDelCalendario.setMonth(finDelCalendario.getMonth()+(this.state.monthsPreview*1)+balance)
                    
                    switch(this.state.producto.noCarrousel){
                        case 1:
                            for(let i=0; i<=this.state.monthsPreview*1+balance; i++){
                                let compare2 = new Date(today.getFullYear(),today.getMonth()+i,1)
                                for(let date of this.state.producto.fechas_inicio){
                                    let courseFinded = [];
                                    courseFinded = await cursosByCategory[categoria].filter(curso => {
                                        return curso.startdate.getMonth() === compare2.getMonth() && curso.startdate.getFullYear() === compare2.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 1
                                    })
                                    if(courseFinded.length === 0){
                                        courseFinded = await cursosByCategory[categoria].filter(curso => {
                                            return curso.startdate.getMonth() === compare2.getMonth() && curso.startdate.getFullYear() === compare2.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 2
                                        })
                                        if(courseFinded.length === 0){
                                            if(compare2 < finDelCalendario){
                                                cursosByCategory[categoria].push({
                                                    id: `${categoria+i+date.getDate()}`,
                                                    idNumber: '',  //Sis Id o sku del curso individual
                                                    startdate: new Date(compare2.getFullYear(),compare2.getMonth(),date.getDate()),
                                                    estado: 0,
                                                    catId: categoria,
                                                    shortname: `${cursosByCategory[categoria][0].categoryname} - ${date.getDate()} ${months[compare2.getMonth()]} ${compare2.getFullYear()}`
                                                });
                                            }
                                        }else if(courseFinded.length > 1){
                                            await courseFinded.forEach(curso1 =>{
                                                cursosByCategory[categoria].forEach(curso2=>{
                                                    if(curso1.id === curso2.id){
                                                        curso2.duplicado = true;
                                                    }
                                                })
                                            })
                                        }
                                    }else if(courseFinded.length > 1){
                                        await courseFinded.forEach(curso1 =>{
                                            cursosByCategory[categoria].forEach(curso2=>{
                                                if(curso1.id === curso2.id){
                                                    curso2.duplicado = true;
                                                }
                                            })
                                        })
                                    }else{
                                        let date = courseFinded[0].startdate
                                        for(let i=0; i < cursosByCategory[categoria].length; i++){
                                            if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                cursosByCategory[categoria].splice(i,1)
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 2:
                            break
                        default:
                            if(this.state.producto.tiene_cap == 1){
                                if(categoria !== this.state.producto.idsMoodle[idioma][this.state.producto.idsMoodle[idioma].length-1]){
                                    
                                    let newCoursesDate = new Date(today.getFullYear(),today.getMonth(),1);
                                    newCoursesDate.setMonth(newCoursesDate.getMonth()-this.state.producto.idsMoodle[idioma].length);
                                    let newCourses = await cursosByCategory[categoria].filter(curso=>curso.startdate >= newCoursesDate)
                                    await newCourses.sort((a,b) => +new Date(a.startdate) - +new Date(b.startdate))

                                    if(newCourses.length > 0){
                                        for(let i=0; i<balance+this.state.monthsPreview*1; i++){
                                            let compare3 = new Date(newCourses[0].startdate.getFullYear(),(newCourses[0].startdate.getMonth()+(balance*i)),1)
                                            for(let date of this.state.producto.fechas_inicio){
                                                let courseFinded = [];
                                                courseFinded = await cursosByCategory[categoria].filter(curso => {
                                                    return curso.startdate.getMonth() === compare3.getMonth() && curso.startdate.getFullYear() === compare3.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 1
                                                })
                                                if(courseFinded.length === 0){
                                                    courseFinded = await cursosByCategory[categoria].filter(curso => {
                                                        return curso.startdate.getMonth() === compare3.getMonth() && curso.startdate.getFullYear() === compare3.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 2
                                                    })
                                                    if(courseFinded.length === 0){
                                                        let finConAjuste = new Date(finDelCalendario);
                                                        finConAjuste.setMonth(finConAjuste.getMonth()-1)
                                                        if(compare3 < finConAjuste){
                                                            cursosByCategory[categoria].push({
                                                                id: `${categoria+i+date.getDate()}`,
                                                                idNumber: '',  //Sis Id o sku del curso individual
                                                                startdate: new Date(compare3.getFullYear(),compare3.getMonth(),date.getDate()),
                                                                estado: 0,
                                                                catId: categoria,
                                                                shortname: `${cursosByCategory[categoria][0].categoryname} - ${date.getDate()} ${months[compare3.getMonth()]} ${compare3.getFullYear()}`
                                                            });
                                                        }
                                                    }else if(courseFinded.length > 1){
                                                        await courseFinded.forEach(curso1 =>{
                                                            cursosByCategory[categoria].forEach(curso2=>{
                                                                if(curso1.id === curso2.id){
                                                                    curso2.duplicado = true;
                                                                }
                                                            })
                                                        })
                                                    }
                                                }else if(courseFinded.length > 1){
                                                    await courseFinded.forEach(curso1 =>{
                                                        cursosByCategory[categoria].forEach(curso2=>{
                                                            if(curso1.id === curso2.id){
                                                                curso2.duplicado = true;
                                                            }
                                                        })
                                                    })
                                                    let date = courseFinded[0].startdate
                                                    for(let i=0; i < cursosByCategory[categoria].length; i++){
                                                        if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                            cursosByCategory[categoria].splice(i,1)
                                                        }
                                                    }
                                                } else{
                                                    let date = courseFinded[0].startdate
                                                    for(let i=0; i < cursosByCategory[categoria].length; i++){
                                                        if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                            cursosByCategory[categoria].splice(i,1)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }else{
                                    let compare3 = new Date(today.getFullYear(),today.getMonth(),1);
                                    compare3.setMonth(compare3.getMonth()+balance);
                                    for(let i=0; i<=this.state.monthsPreview*1+balance; i++){
                                        if(compare3 < finDelCalendario){
                                            for(let date of this.state.producto.fechas_inicio){
                                                let courseFinded = [];
                                                courseFinded = await cursosByCategory[categoria].filter(curso => {
                                                    return curso.startdate.getMonth() === compare3.getMonth() && curso.startdate.getFullYear() === compare3.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 1
                                                })
                                                if(courseFinded.length === 0){
                                                    courseFinded = await cursosByCategory[categoria].filter(curso => {
                                                        return curso.startdate.getMonth() === compare3.getMonth() && curso.startdate.getFullYear() === compare3.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 2
                                                    })
                                                    if(courseFinded.length === 0){
                                                        if(compare3 < finDelCalendario){
                                                            cursosByCategory[categoria].push({
                                                                id: `${categoria+i+date.getDate()}`,
                                                                startdate: new Date(compare3.getFullYear(),compare3.getMonth(),date.getDate()),
                                                                estado: 0,
                                                                catId: categoria,
                                                                shortname: !!cursosByCategory[categoria][0] ? `${cursosByCategory[categoria][0].categoryname} - ${date.getDate()} ${months[compare3.getMonth()]} ${compare3.getFullYear()}` : ''
                                                            })
                                                        }
                                                    }else if(courseFinded.length > 1){
                                                        await courseFinded.forEach(curso1 =>{
                                                            cursosByCategory[categoria].forEach(curso2=>{
                                                                if(curso1.id === curso2.id){
                                                                    curso2.duplicado = true;
                                                                }
                                                            })
                                                        })
                                                    }
                                                }else if(courseFinded.length > 1){
                                                    await courseFinded.forEach(curso1 =>{
                                                        cursosByCategory[categoria].forEach(curso2=>{
                                                            if(curso1.id === curso2.id){
                                                                curso2.duplicado = true;
                                                            }
                                                        })
                                                    })
                                                    let date = courseFinded[0].startdate
                                                    for(let i=0; i < cursosByCategory[categoria].length; i++){
                                                        if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                            cursosByCategory[categoria].splice(i,1)
                                                        }
                                                    }
                                                }else{
                                                    let date = courseFinded[0].startdate
                                                    for(let i=0; i < cursosByCategory[categoria].length; i++){
                                                        if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                            cursosByCategory[categoria].splice(i,1)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        compare3.setDate(1)
                                        compare3.setMonth(compare3.getMonth()+1);
                                    }
                                }
                            }else{
                                let newCoursesDate = new Date(today.getFullYear(),today.getMonth(),1);
                                    newCoursesDate.setMonth(newCoursesDate.getMonth()-this.state.producto.idsMoodle[idioma].length);
                                    let newCourses = await cursosByCategory[categoria].filter(curso=>curso.startdate >= newCoursesDate)
                                    await newCourses.sort((a,b) => +new Date(a.startdate) - +new Date(b.startdate))

                                    if(newCourses.length > 0){
                                        for(let i=0; i<balance+this.state.monthsPreview*1; i++){
                                            let compare3 = new Date(newCourses[0].startdate.getFullYear(),(newCourses[0].startdate.getMonth()+(balance*i)),1)
                                            for(let date of this.state.producto.fechas_inicio){
                                                let courseFinded = [];
                                                courseFinded = await cursosByCategory[categoria].filter(curso => {
                                                    return curso.startdate.getMonth() === compare3.getMonth() && curso.startdate.getFullYear() === compare3.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 1
                                                })
                                                if(courseFinded.length === 0){
                                                    courseFinded = await cursosByCategory[categoria].filter(curso => {
                                                        return curso.startdate.getMonth() === compare3.getMonth() && curso.startdate.getFullYear() === compare3.getFullYear() && date.getDate() === curso.startdate.getDate() && curso.estado === 2
                                                    })
                                                    if(courseFinded.length === 0){
                                                        let finConAjuste = new Date(finDelCalendario);
                                                        finConAjuste.setMonth(finConAjuste.getMonth()-1)
                                                        if(compare3 < finConAjuste){
                                                            cursosByCategory[categoria].push({
                                                                id: `${categoria+i+date.getDate()}`,
                                                                idNumber: '',  //Sis Id o sku del curso individual
                                                                startdate: new Date(compare3.getFullYear(),compare3.getMonth(),date.getDate()),
                                                                estado: 0,
                                                                catId: categoria,
                                                                shortname: `${cursosByCategory[categoria][0].categoryname} - ${date.getDate()} ${months[compare3.getMonth()]} ${compare3.getFullYear()}`
                                                            });
                                                        }
                                                    }else if(courseFinded.length > 1){
                                                        await courseFinded.forEach(curso1 =>{
                                                            cursosByCategory[categoria].forEach(curso2=>{
                                                                if(curso1.id === curso2.id){
                                                                    curso2.duplicado = true;
                                                                }
                                                            })
                                                        })
                                                    }
                                                }else if(courseFinded.length > 1){
                                                    await courseFinded.forEach(curso1 =>{
                                                        cursosByCategory[categoria].forEach(curso2=>{
                                                            if(curso1.id === curso2.id){
                                                                curso2.duplicado = true;
                                                            }
                                                        })
                                                    })
                                                    let date = courseFinded[0].startdate
                                                    for(let i=0; i < cursosByCategory[categoria].length; i++){
                                                        if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                            cursosByCategory[categoria].splice(i,1)
                                                        }
                                                    }
                                                } else{
                                                    let date = courseFinded[0].startdate
                                                    for(let i=0; i < cursosByCategory[categoria].length; i++){
                                                        if(cursosByCategory[categoria][i].startdate.getDate() === date.getDate() && cursosByCategory[categoria][i].startdate.getMonth() === date.getMonth() && cursosByCategory[categoria][i].startdate.getFullYear() === date.getFullYear() && cursosByCategory[categoria][i].estado === 2){
                                                            cursosByCategory[categoria].splice(i,1)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                            }
                            
                            break;
                        }
                    await cursosByCategory[categoria].sort((a,b) => +new Date(a.startdate) - +new Date(b.startdate))
                }
                this.setState({
                    ...this.state,
                    cursos:{
                        ...this.state.cursos,
                        [idioma]:cursosByCategory
                    },
                })
            }
            this.setState({...this.state,loading:{...this.state.loading,cursos: false},reload:{apiCalendarios:false,cursosMoodle:false}})
        }catch(error){
            this.setState({
                ...this.state,
                modal:{
                    ...this.state.modal,
                    open: true,
                    error: true,
                    text: 'Ocurrio un error al obtener los cursos del producto'
                }
            })
            console.log(error);
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            }
        }))
        this.obtenerCursos();
    }

    handleModalCalendario(){
        this.setState(prevState => ({
            calendario: !prevState.calendario,
        }))
    }

    handleModalSecciones(categoria){
        this.setState(prevState => ({
            ShowHideModalSecciones: !prevState.ShowHideModalSecciones,
            modalSeccionesCategoria: categoria,
        }))
    }

    handleModalProducto(){
        if(!this.state.loading.cursos){
            this.setState(prevState => ({
                ...this.state,
                ShowHideModalProducto: !prevState.ShowHideModalProducto,
                dateSelected: this.state.producto.fechas_inicio,
            }))
        }
    }

    handleModalConfirmacion(){
        this.setState(prevState => ({
            ...this.state,
            ShowHideModalConfirmacion: !prevState.ShowHideModalConfirmacion,
            loading:{
                ...this.state.loading,
                cursos: true
            }
        }))
        this.obtenerCursos();
    }

    handleCambioIdioma(idioma){
        this.setState({
            ...this.state,
            idiomaSelected: idioma,
        })
    }

    handleSelect(e){
        if(!this.state.loading.cursos){
        
            let fechaEnrolment = new Date(e.target.value+' 00:00:00');
            let reset = document.querySelectorAll('li.calendario-capsulas')
            reset.forEach(capsula => capsula.classList.remove('enrolment-start-date'));
            
            let enrolment = [];
            switch(this.state.producto.noCarrousel){
                case 1:
                    this.state.producto.idsMoodle[this.state.idiomaSelected].forEach(categoria =>{
                        enrolment.push(this.state.cursos[this.state.idiomaSelected][categoria].filter(curso => {
                            let fechaCurso = new Date(curso.startdate);
                            return fechaCurso.getDate() === fechaEnrolment.getDate() && fechaCurso.getMonth() === fechaEnrolment.getMonth() && fechaCurso.getFullYear() === fechaEnrolment.getFullYear();
                        }))
                        fechaEnrolment.setMonth(fechaEnrolment.getMonth()+1);
                    })
                    break;
                case 2:
                    break;
                default:
                    enrolment = [];
                    let foundedCategories = [];
                    let repeatCount = 0;
                    for(let i = 0; i < this.state.producto.idsMoodle[this.state.idiomaSelected].length; i++){
                        let found = [];
                        if(this.state.producto.idsMoodle[this.state.idiomaSelected][i] !== this.state.producto.idsMoodle[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected].length]){
                            for(let categoria of this.state.producto.idsMoodle[this.state.idiomaSelected]){
                                if(categoria !== this.state.producto.idsMoodle[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected].length] && !foundedCategories.includes(categoria)){
                                    found = this.state.cursos[this.state.idiomaSelected][categoria].filter(curso => {
                                        let fechaCurso = new Date(curso.startdate);
                                        return fechaCurso.getDate() === fechaEnrolment.getDate() && fechaCurso.getMonth() === fechaEnrolment.getMonth() && fechaCurso.getFullYear() === fechaEnrolment.getFullYear();
                                    })
                                    if(found.length !== 0){
                                        enrolment.push(found);
                                        foundedCategories.push(categoria);
                                        repeatCount = 0;
                                        fechaEnrolment.setMonth(fechaEnrolment.getMonth()+(1-repeatCount));
                                        break;
                                    }
                                }
                            }
                        }else if(!foundedCategories.includes(this.state.producto.idsMoodle[this.state.idiomaSelected][i])){
                            found = this.state.cursos[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected][i]].filter(curso => {
                                let fechaCurso = new Date(curso.startdate);
                                return fechaCurso.getDate() === fechaEnrolment.getDate() && fechaCurso.getMonth() === fechaEnrolment.getMonth() && fechaCurso.getFullYear() === fechaEnrolment.getFullYear();
                            })
                            if(found.length !== 0){
                                enrolment.push(found);
                                fechaEnrolment.setMonth(fechaEnrolment.getMonth()+(1-repeatCount));
                                repeatCount = 0;
                                foundedCategories.push(this.state.producto.idsMoodle[this.state.idiomaSelected][i])
                                break;
                            }
                        }
                        if(found.length === 0 && repeatCount <= (this.state.producto.idsMoodle[this.state.idiomaSelected].length - foundedCategories.length)){
                            fechaEnrolment.setMonth(fechaEnrolment.getMonth()+1);
                            i--;
                            repeatCount ++;
                        }else{
                            fechaEnrolment.setMonth(fechaEnrolment.getMonth()-repeatCount);
                        }
                    }
                    break;
            }
            enrolment.forEach(categoria => {
                categoria.forEach(curso => {
                    let capsulas = document.getElementById(`${curso.id+''+curso.startdate+curso.catId}`)
                    if(!!capsulas){
                        capsulas.classList.add("enrolment-start-date");
                    }
                })
            })
        }
    }

    async handleSelectDuracion(e){
        if(!this.state.loading.cursos){
            this.setState({
                ...this.state,
                monthsPreview: e.target.value,
                loading:{
                    ...this.state.loading,
                    cursos: true
                },
            })
            for(let idioma of Object.keys(this.state.producto.idsMoodle)){
                for(let categoria of this.state.producto.idsMoodle[idioma]){
                    let existentes = [];
                    existentes = await this.state.cursos[idioma][categoria].filter(curso=>curso.estado !== 0);
                    this.setState({
                        ...this.state,
                        cursos:{
                            ...this.state.cursos,
                            [idioma]:{
                                ...this.state.cursos[idioma],
                                [categoria]: existentes
                            }
                        }
                    })
                }
            }
            this.obtenerCursos();
        }
    }

    modalValidacion() {
        return (
            <div className="custom-modal" style={{zIndex: '100000'}}>
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Calendarios</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.handleModal}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {this.state.modal.text ?
                            <div>
                                {this.state.modal.error === true ?
                                    <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    :
                                    <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                }
                                <p>{this.state.modal.text}</p>
                            </div>
                        : <></>}
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    modalCalendario(ids){
        const columns = [
            {
                dataField: 'id',
                text: 'Id',
                align: 'center',
                headerClasses: 'border-0 p-2',
                uniqueData: "id",
                headerStyle: () => {
                    return { width: "50px",textAlign:'center' };
                },
                formatter: (cellContent, row) => {
                    return (
                        <span className="badge badge-primary">{row.id}</span>
                    );
                },
                style:{
                    with: '50px',
                    padding: 0
                }
            },
            {
                dataField: 'nombre',
                text: 'Nombre',
                headerClasses: 'border-0 p-2',
                headerStyle: () => {
                    return { width: "90%" };
                },
                uniqueData: "nombre",
                style: {
                    width: "90%",
                    padding: 0
                }
            }
        ];

        const expandRow = {
            renderer: (row, rowIndex) => {
                return(<>
                    <div className='fechas'>
                        <ul className='d-flex flex-row justify-content-center flex-wrap px-0 mb-0' style={{listStyle:'none', overflowX: 'auto'}}>
                        {this.state.cursos[this.state.idiomaSelected][row.id].map(curso =>{
                            return this.dateBadgeMoodle(curso)
                        })}
                        </ul>
                    </div>
                </>)
            },
            expanded: !!ids ? ids : [],
            nonExpandable: !!ids ? ids : [],
            // showExpandColumn: true,
            className: 'p-0'
        };
        const rowClasses = 'custom-row-class';

        if(!!this.state.calendario){
            return <>
                <div className="my-modal">
                    <div className="my-modal-content max-w-90 max-h-95">
                        <div className="my-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className='d-flex flex-row'>
                                    <h6>Calendario</h6>
                                    <span className="badge badge-info ml-2">{this.state.producto.noCarrousel === 1 ? 'No Carrousel' : this.state.producto.noCarrousel === 2 ? 'Blend' : 'Carrousel'}</span>
                                </div>
                                <div>
                                    <div className='d-flex flex-row align-items-center'>
                                        <label className='text-white mb-0 mx-2 pl-2' style={{whiteSpace: 'nowrap', fontWeight: '500'}} htmlFor='months_preview'>Duracion del calendario</label>
                                        <select type="text" value={this.state.monthsPreview} className="form-control mb-0 mx-2" name="months_preview" onChange={this.handleSelectDuracion}>
                                            <option value={2}>2 Meses</option>
                                            <option value={3}>3 Meses</option>
                                            <option value={4}>4 Meses</option>
                                            <option value={5}>5 Meses</option>
                                            <option value={6}>6 Meses</option>
                                            <option value={12}>1 Año</option>
                                            <option value={18}>1 Año y 6 Meses</option>
                                            <option value={24}>2 Años</option>
                                        </select> 
                                        <label className='text-white mb-0 mx-2 pl-2' style={{whiteSpace: 'nowrap', fontWeight: '500', borderLeft: '3px solid white'}} htmlFor='enrolment_date'>Simule fecha de inicio</label>
                                        <select type="text" className="form-control mb-0 mx-2" name="enrolment_date" onChange={this.handleSelect}>
                                            <option value={0}>Seleccione fecha</option>
                                            {this.getSelectDates()}
                                        </select>
                                        <button onClick={() => this.handleModalCalendario()} className="btn btn-close mb-3 ml-1"><i className="fa fa-times"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-modal-body max-h-95 text-left pt-0 px-2" style={{paddingBottom: '90px'}}>
                            {!!this.state.loading.cursos ? <div className="pt-5"><Loader /></div> :
                                <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                    <ToolkitProvider
                                        keyField='id'
                                        data={this.state.producto.cursos[this.state.idiomaSelected]}
                                        columns={columns}
                                    >
                                        {
                                            props => (
                                                <Fragment>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        bordered={false}
                                                        headerClasses="header-class"
                                                        {...props.baseProps}
                                                        rowClasses={rowClasses}
                                                        paginationSize={50}
                                                        expandRow={ expandRow }
                                                    />
                                                </Fragment>
                                            )
                                        }
                                    </ToolkitProvider>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <button className='btn btn-primary' disabled={this.state.loading.calendarios ? true : false} onClick={this.guardarCalendario}>{this.state.loading.calendarios ? <LoaderSecondary/> : 'Guardar Calendario'}</button>
                                        <button className='btn btn-secondary ml-2' disabled={this.state.loading.calendarios ? true : false} onClick={this.handleModalConfirmacion}>{this.state.loading.calendarios ? <LoaderSecondary/> : 'Crear en LMS'}</button>
                                    </div>
                                </Animated>
                            }
                        </div>
                    </div>
                </div>
            </>
        }
    }

    modalSecciones(){
        if(!!this.state.ShowHideModalSecciones){
            return(
                <ListSeccionesMoodle handleFuncion={this.handleModalSecciones} reloadApi={this.reloadApiCourses} categoriaNombre={this.state.producto.cursos[this.state.idiomaSelected]} cursos={this.state.cursos[this.state.idiomaSelected]} categoria={this.state.modalSeccionesCategoria} prodId={this.state.producto.id}/>
                )
        }
    }

    modalProducto(){
        if(!!this.state.ShowHideModalProducto){
            return(<>
                <div className="custom-modal">
                    <div className="custom-modal-content">

                        <div className="custom-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>{this.state.producto.nombre}</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={this.handleModalProducto}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="right-body bg-white text-center justify-content-center">
                            <h6>Seleccione fechas de inicio</h6>
                            <DayPicker
                                mode="multiple"
                                selected={this.state.dateSelected}
                                fromDate={new Date(2023, 0, 1)}
                                toDate={new Date(2023,0,31)}
                                disableNavigation
                                onSelect={this.setSelected}
                                // footer={footer}
                            />
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-stretch">
                            <button className='btn btn-primary mr-2' onClick={this.guardarProducto}>{this.state.loading.productoUpdate ? <LoaderSecondary/> : 'Guardar'}</button>
                            <button className="btn btn-secondary mr-2" onClick={this.handleModalProducto} disabled={this.state.loading.productoUpdate}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </>)
        }
    }

    modalConfirmacion(){
        if(!!this.state.ShowHideModalConfirmacion){
            return(<>
                <div className="custom-modal">
                    <div className="custom-modal-content">

                        <div className="custom-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>{this.state.producto.nombre}</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={this.handleModalConfirmacion} disabled={this.state.loading.cursos ? true :false}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="right-body bg-white text-center justify-content-center">
                            <span>Se crearan en el LMS todos los cursos en estado <strong>"Confirmado"</strong>. Desea continuar?</span>
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-stretch">
                            <button className='btn btn-primary mr-2' onClick={this.crearEnMoodle}>{this.state.loading.cursos ? <LoaderSecondary/> : 'Guardar'}</button>
                            <button className="btn btn-secondary mr-2" onClick={this.handleModalConfirmacion} disabled={this.state.loading.cursos ? true :false}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </>)
        }
    }

    async guardarCalendario(){
        if(!this.state.loading.cursos){
            this.setState({
                ...this.state,
                loading:{
                    ...this.state.loading,
                    calendarios: true
                }
            })
            let vacioCount = 0;
            for(let categoria of this.state.producto.idsMoodle[this.state.idiomaSelected]){
                let cursosACrear = await this.state.cursos[this.state.idiomaSelected][categoria].filter(curso=>curso.estado === 0);
                if(cursosACrear.length === 0){
                    vacioCount ++;
                }else {
                    for(let curso of cursosACrear){
                        try{
                            let date = new Date(curso.startdate);
                            let mes = date.getMonth()+1;
                            let formatDate = `${date.getFullYear()}-${mes<10 ? '0' : ''}${mes}-${date.getDate()}`;
                            let body ={
                                id_categoria: curso.catId,
                                fecha_inicio: formatDate,
                                id_cliente: this.state.cliente.id,
                                estado: 2
                            }
                            await apiCertificaciones.postCursoCalendario(localStorage.getItem('token'),body);
                        }catch(e){
                            console.log(e);
                            this.setState({
                                ...this.state,
                                loading:{
                                    ...this.state.loading,
                                    calendarios: false
                                },
                                modal:{
                                    open:       true,
                                    text:       'Ocurrio un error al guardar el calendario',
                                    error:      true,
                                },
                            })
                        }                    
                    }
                }
            }
            if(vacioCount === Object.keys(this.state.cursos[this.state.idiomaSelected]).length){
                this.setState({
                    ...this.state,
                    modal:{
                        open: true,
                        error: true,
                        text: 'No existen cursos a crear'
                    },
                    loading:{
                        ...this.state.loading,
                        cursos: true,
                        calendarios: false
                    }
                })
            }else{
                this.setState({
                    ...this.state,
                    loading:{
                        ...this.state.loading,
                        cursos: true,
                        calendarios: false
                    },
                    modal:{
                        open:       true,
                        text:       'Calendario guardado correctamente',
                        error:      false,
                    },
                    reload:{
                        ...this.state.reload,
                        apiCalendarios: true
                    }
                })
            }
        }
    }

    async ajusteHorario(fecha, categoria){

        let newInicio = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}, 00:00:00`
        let formatStartDate = await apiCertificaciones.convertTimeZone(localStorage.getItem('token'), newInicio, this.state.cliente.id)
        let mesFin = fecha.getMonth()
        if(this.state.producto.idsMoodle[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected].length-1] === categoria){
            mesFin += 5
        }else{
            mesFin += 2
        }
        let balanceAño = 0
        if(mesFin > 12){
            mesFin -= 12
            balanceAño +=1
        }
        let newEndDate = `${fecha.getFullYear()+balanceAño}-${mesFin}-${fecha.getDate()}, 23:59:59`;
        let formatEndDate = await apiCertificaciones.convertTimeZone(localStorage.getItem('token'), newEndDate, this.state.cliente.id)
        
        return {
            inicio: formatStartDate.fecha,
            fin: formatEndDate.fecha
        }
    }

    async crearEnMoodle(){
        this.setState({
            ...this.state,
            loading:{
                ...this.state.loading,
                cursos:true
            },
            reload:{
                ...this.state.reload,
                cursosMoodle: true,
                apiCalendarios: true,
            },
        })
        let cursosACrear = [];

        for(let categoria of Object.keys(this.state.cursos[this.state.idiomaSelected])){
            let categoryIndex = this.state.producto.idsMoodle[this.state.idiomaSelected].indexOf(categoria*1)
            let balance = this.state.producto.noCarrousel === 1 ? categoryIndex : this.state.producto.idsMoodle[this.state.idiomaSelected].length;
            let finDelCalendario = new Date(today)
            finDelCalendario.setDate(28)
            finDelCalendario.setMonth(finDelCalendario.getMonth()+(this.state.producto.noCarrousel === 1 ? this.state.monthsPreview*1+balance : this.state.producto.idsMoodle[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected].length-1] === categoria*1 ? this.state.monthsPreview*1+balance-1 : this.state.monthsPreview*1+balance-2))
            for(let curso of this.state.cursos[this.state.idiomaSelected][categoria]){
                if(curso.estado === 2){
                    if(curso.startdate <= finDelCalendario && curso.startdate >= today){
                        
                        let fechas = await this.ajusteHorario(curso.startdate, categoria*1)

                        curso.fullname = curso.fullname.replace('&amp;', 'y');
                        curso.shortname = curso.shortname.replace('&amp;', 'y');

                        cursosACrear.push({
                            fullname: curso.fullname,
                            catId: curso.catId,
                            shortname: curso.shortname,
                            stardate: fechas.inicio,
                            enddate: fechas.fin,
                            sku: curso.sku,
                        }
                        )
                    }
                }
            }
        }

        try{
            for(let curso of cursosACrear){
                await moodle.createCourse(curso,this.state.cliente)
            }            
            this.handleModalConfirmacion()


        }catch(e){
            console.log(e)
            this.setState({
                reload:{
                    cursosMoodle: true,
                    apiCalendarios: true,
                },
            })
        }
    }

    async guardarProducto(){      
        for(let idioma of Object.keys(this.state.producto.idsMoodle)){
            for(let categoria of this.state.producto.idsMoodle[idioma]){
                let existentes = [];
                existentes = await this.state.cursos[idioma][categoria].filter(curso=>curso.estado !== 0);
                this.setState({
                    ...this.state,
                    cursos:{
                        ...this.state.cursos,
                        [idioma]:{
                            ...this.state.cursos[idioma],
                            [categoria]: existentes
                        }
                    }
                })
            }
        }
        this.setState({
            ...this.state,
            producto:{
                ...this.state.producto,
                fechas_inicio: this.state.dateSelected,
            },
            loading:{
                ...this.state.loading,
                cursos: true,
                productoUpdate: true
            },
            ShowHideModalProducto: !this.state.ShowHideModalProducto
        })
        try{
            let fechas = [];
            this.state.producto.fechas_inicio.forEach(fecha=>{
                fechas.push(fecha.getDate());
            })

            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Content-Type", "application/json")

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    sku: this.state.producto.sku,
                    fechas_inicio: fechas,
                    categorias: this.state.producto.categorias,
                    precio: this.state.producto.precio,
                    estado: this.state.producto.estado,
                    cantidadDeCuotas: this.state.producto.cantidadDeCuotas
                })
            };

            console.log(requestOptions.body)

            fetch(window.$UrlOctavio + "/productos/" + this.state.producto.id, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({
                        ...this.state,
                        loading:{
                            ...this.state.loading,
                            productoUpdate: false
                        }
                    })                
                    this.obtenerCursos();
                })
                .catch(error => {
                    this.setState({
                        ...this.state,
                        loading:{
                            ...this.state.loading,
                            producto: false
                        },
                        modal:{
                            ...this.state.modal,
                            open: true,
                            error: true,
                            text: 'Ocurrio un error al actualizar el producto'
                        }
                    })
                    console.log('error', error)
                })
        }catch(error){
            console.log(error);
        }
    }

    async reloadApiCourses(){
        this.setState({
            ...this.state,
            loading:{
                ...this.state.loading,
                cursos: true
            },
            ShowHideModalSecciones: false,
            reload:{
                ...this.state.reload,
                apiCalendarios: true
            },
        })
        await new Promise((res,rej)=>setTimeout(()=>{res()},10))
        this.obtenerCursos()
    }

    dateBadgeMoodle(curso){
        let startDate = curso.startdate;
        let categoria = curso.estado !== 1 ? curso.catId*1 : curso.categoryid*1;
        let categoryIndex = this.state.producto.idsMoodle[this.state.idiomaSelected].indexOf(categoria)
        
        let balance = this.state.producto.noCarrousel === 1 ? categoryIndex : this.state.producto.idsMoodle[this.state.idiomaSelected].length;
        
        let filterNewCourses = this.state.producto.noCarrousel === 1 ? new Date(today.getFullYear(),today.getMonth()+balance,1) : this.state.producto.idsMoodle[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected].length-1] === categoria ? new Date(today.getFullYear(),today.getMonth()+balance-1,1) : new Date(today.getFullYear(),today.getMonth(),1);
        let finDelCalendario = new Date(today)
        finDelCalendario.setDate(28)
        finDelCalendario.setMonth(finDelCalendario.getMonth()+(this.state.producto.noCarrousel === 1 ? this.state.monthsPreview*1+balance : this.state.producto.idsMoodle[this.state.idiomaSelected][this.state.producto.idsMoodle[this.state.idiomaSelected].length-1] === categoria ? this.state.monthsPreview*1+balance-1 : this.state.monthsPreview*1+balance-2))

        let mes = months[startDate.getMonth()];
        if(startDate >= filterNewCourses){
            if(curso.estado !==0){
                if(startDate <= finDelCalendario){
                    return (
                        <div key={curso.id+''+curso.startdate+curso.catId} id={startDate.getTime()+''+curso.catId} /*style={styleItem}*/>
                            <li id={curso.id+''+curso.startdate+curso.catId} className='mr-1 mb-1 d-flex flex-column justify-content-center calendario-capsulas'>
                                <span className={`badge ${curso.duplicado ? 'badge-dark' : 'badge-info'}`} style={{marginBottom: '2px'}}>{curso.estado !== 0 ? curso.id : 'N/a'}</span>
                                <span className={`badge d-flex flex-column badge-${curso.estado === 1 ? 'success' : curso.estado === 2 ? 'warning' : 'danger'}`}>
                                    <span>{startDate.getDate()}</span>
                                    <span>{mes}</span>
                                    <span>{startDate.getFullYear()}</span>
                                </span>
                            </li>
                        </div>
                    )
                }
            }else{
                return (
                    <div key={curso.id+''+curso.startdate+curso.catId} id={startDate.getTime()+''+curso.catId} /*style={styleItem}*/>
                        <li id={curso.id+''+curso.startdate+curso.catId} className='mr-1 mb-1 d-flex flex-column justify-content-center calendario-capsulas'>
                            <span className={`badge ${curso.duplicado ? 'badge-dark' : 'badge-info'}`} style={{marginBottom: '2px'}}>{curso.estado !== 0 ? curso.id : 'N/a'}</span>
                            <span className={`badge d-flex flex-column badge-${curso.estado === 1 ? 'success' : curso.estado === 2 ? 'warning' : 'danger'}`}>
                                <span>{startDate.getDate()}</span>
                                <span>{mes}</span>
                                <span>{startDate.getFullYear()}</span>
                            </span>
                        </li>
                    </div>
                )
            }
        }
    }

    getSelectDates(){
        let date = new Date(today);
        let options = [];
        for(let i=0; i<=this.state.monthsPreview; i++){
            this.state.producto.fechas_inicio.forEach(startDate =>{
                options.push(<option key={i+' '+startDate.getDate()} value={date.getFullYear()+'-'+(date.getMonth()+1)+'-'+startDate.getDate()}>{startDate.getDate()+'-'+months[date.getMonth()]+'-'+date.getFullYear()}</option>)
            })
            date.setDate(10)
            date.setMonth(date.getMonth()+1)
        }
        return options;
    }

    setSelected(days){
        if(days.length > 5){
            this.setState({
                ...this.state,
                modal:{
                    open:       true,
                    text:       'Supera el limite permitido de fechas de inicio',
                    error:      true,
                }
            })
        } else{
            this.setState({
                dateSelected: days
            })
        }
    }

    renderProducto() {
        return (
            <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="box-white sticky-top">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>Información del producto</h6>
                                    <button className='btn btn-primary' onClick={this.handleModalProducto}>Editar</button>
                                </div>
                                <hr />
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Id Octavio</span>
                                            <span className="badge badge-info">{this.state.producto.id}</span>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>SKU</span>
                                            <span className="badge badge-secondary">{this.state.producto.sku}</span>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Tipo</span>
                                            <span className="badge badge-secondary">{this.state.producto.tipo}</span>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Fechas de inicio</span>
                                            {this.state.loading.productoUpdate ? <Loader/> :
                                                <span className="badge badge-info">{this.state.producto.fechas_inicio.map((date,i) =>{
                                                    if(i===0){
                                                        return date.getDate()
                                                    }else{
                                                        return ', '+date.getDate()
                                                    }
                                                })}</span>
                                            }
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>No Carrousel</span>
                                            {this.state.producto.noCarrousel === 0 ? <span className="badge badge-danger">No</span> : <span className="badge badge-success">Si</span>}
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Id Hubspot</span>
                                            <span className="badge badge-secondary">{this.state.producto.idHubspot}</span>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Id Magento</span>
                                            <span className="badge badge-secondary">{this.state.producto.idMagento}</span>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>LMS</span>
                                            <span className="badge badge-secondary">{this.state.idsCanvas === '{}' ? 'Canvas' : 'Moodle'}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                            <div className="box-white">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h6 className="m-0">Información de categorías</h6>
                                    <button className="btn btn-primary" onClick={this.handleModalCalendario}>Ver calendario</button>
                                </div>
                                <hr />
                                {this.state.producto.cursos ? <>
                                    <nav>
                                        <div className="nav nav-tabs" id="tabIdiomas" role="tablist">
                                            {Object.keys(this.state.producto.cursos).map((idioma, i) => {
                                                return(
                                                idioma === this.state.idiomaSelected ?
                                                    <a key={i} className="nav-link active" onClick={() => this.handleCambioIdioma(idioma)} data-toggle="tab" href={`#id${i}`} role="tab" aria-selected="true">Idioma: {idioma}</a>
                                                    :
                                                    <a key={i} className="nav-link" onClick={() => this.handleCambioIdioma(idioma)} data-toggle="tab" href={`#id${i}`} role="tab" aria-selected="true">Idioma: {idioma}</a>
                                                )
                                            })}
                                        </div>
                                    </nav>
                                    <div className="tab-content mt-3">
                                        {Object.keys(this.state.producto.cursos).map((idioma, i) => (
                                            i === 0 ?
                                                <div key={i} className="tab-pane fade active show" id={`id${i}`} role="tabpanel">
                                                    <div className="list-group">
                                                        {this.state.producto.cursos[idioma].map(item => {
                                                            return <li key={item.id} className="list-group-item">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <span className="badge badge-primary mr-2">{item.id}</span>
                                                                        <span>{item.nombre}</span>
                                                                    </div>
                                                                    <div>
                                                                        <button id={`botonSecciones${item.id}`} disabled={this.state.loading.cursos ? true : false} type="button" className="btn btn-secondary botonSecciones" onClick={() => this.handleModalSecciones(item.id)}>{this.state.loading.cursos ? <LoaderSecondary/> : 'Ver secciones'}</button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="tab-pane fade" id={`id${i}`} role="tabpanel">
                                                    <div className="list-group">
                                                        {this.state.producto.cursos[idioma].map(item => {
                                                            return <li key={item.id} className="list-group-item">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <span className="badge badge-primary mr-2">{item.id}</span>
                                                                        <span>{item.nombre}</span>

                                                                    </div>
                                                                    <div>
                                                                        <button id={`botonSecciones${item.id}`} disabled={this.state.loading.cursos ? true : false} type="button" className="btn btn-secondary botonSecciones" onClick={() => this.handleModalSecciones(item.id)}>{this.state.loading.cursos ? <LoaderSecondary/> : 'Ver secciones'}</button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    :
                                    <></>}
                                </div>
                            </div>
                    </div>
                </div>
            </Animated>
        )
        
    }

    route(){
        return (
            <>
                <div className="bg-light pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="navigation-breadcrumb">Inicio &gt; Calendarios</label>
                                <div className="d-flex align-items-center">
                                    <Link to={"/calendarios"} className="btn btn-transparent mr-3 border"><i className="fas fa-arrow-left"></i></Link>
                                    <h5 className="m-0">Producto / {!!this.state.loading.producto ? <LoaderSecondary></LoaderSecondary> : this.state.producto.nombre}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbarstiky bg-white mb-4 pb-3 pt-3">
                    <div className="container">
                        <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (<>
            {this.state.cliente !== undefined && this.state.cliente !== null ? <></> : <Redirect from="/" to="/calendarios"/>}
            {this.state.modal.open ? this.modalValidacion() : <></>}
            {this.modalSecciones()}
            {this.modalProducto()}
            {this.modalConfirmacion()}
            {this.modalCalendario(this.state.producto ? this.state.producto.idsMoodle[this.state.idiomaSelected] : null )}
            <Navigation />
            {this.route()}
            {this.state.loading.producto === true ? <Loader/> : this.renderProducto()}
            {console.log(this.state)}
        </>)
    }
}

$('#tabIdiomas a').on('click', function (event) {
    event.preventDefault()
    $(this).tab('show')
})
